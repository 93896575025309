import {KTIcon, KTSVG} from '_metronic/helpers'
import {API_URL} from 'app/consts/consts'
import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {showErrorToaster, showSuccessToaster} from 'utils/toasters'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
interface IProgram {
  id: number
  name: string
  eqf_level: number | null
  ects: number | null
  total_training: number
  active_training: number
  authorization_required: boolean
  tasks_count: number
  status: string
}
const AllProgramsTable = () => {
  const [programs, setPrograms] = useState<IProgram[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getPrograms = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/programs`)
      setPrograms(response.data.data)
      console.log(response.data)
      setIsLoading(false)
    } catch (err) {
      setPrograms([])
      showErrorToaster('Sorry, something went wrong')
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getPrograms()
  }, [])
  return (
    <div className='card card-flush h-xl-100 cursor-pointer'>
      <div className='card-header'>
        <div className='card-title flex-column'>
          <h3 className='mt-4'>Programs</h3>
          <p className='fs-9 text-secondary fw-light'>{programs.length} programs listed</p>
        </div>
        <div className='card-toolbar'>
          <Link type='button' className='btn btn-sm btn-primary' to={'new-program'}>
            New Program
          </Link>
        </div>
      </div>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='v-100 position-relative' style={{minHeight: '400px'}}>
            <div
              className='page-loading position-absolute'
              style={{
                top: '50%',
                left: '50%',
              }}
            >
              <span
                className='spinner-border text-primary'
                data-kt-app-page-loading='on'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </span>
            </div>
          </div>
        ) : (
          <div className=''>
            <table className='table table-row-bordered table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='fw-bolder text-secondary'>Name</th>
                  <th className='fw-bolder text-secondary'>EQF Level</th>
                  <th className='fw-bolder text-secondary'>ECTS</th>
                  <th className='fw-bolder text-secondary'>Task</th>
                  <th className='fw-bolder text-secondary'>Steps</th>
                  <th className='fw-bolder text-secondary'>STATUS</th>
                  <th className='fw-bolder text-secondary text-center'>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {programs.map((program) => (
                  <tr>
                    <td>{program?.name}</td>
                    <td>{program?.eqf_level ? program?.ects : 0}</td>
                    <td>{program?.ects ? program?.ects : 0}</td>
                    <td>{program?.tasks_count}</td>
                    <td>{program?.tasks_count}</td>
                    <td>
                      {program.status === 'in_progress' && (
                        <span className='badge badge-secondary px-5 py-2' style={{color: '#FFF'}}>Draft</span>
                      )}
                      {program.status === 'active' && (
                        <span className='badge badge-primary px-5 py-2'>Completed</span>
                      )}
                      {program.status === 'need_action' && (
                        <span className='badge badge-warning px-5 py-2'>Content Required</span>
                      )}
                    </td>
                    <td className='text-center'>
                      <Link
                        className='btn btn-sm btn-light px-1 py-1'
                        to={`edit-program/${program?.id}`}
                      >
                        <KTSVG path='/media/svg/Write.svg' className='svg-icon svg-icon-2x' />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ToastContainer/>
    </div>
  )
}

export default AllProgramsTable
