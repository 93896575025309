import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from '../types/_models'
import * as authHelper from '../utils/AuthHelpers'
import { getUserByToken } from '../api/_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { access } from 'fs'
import { useNavigate } from 'react-router-dom'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  // const navigate = useNavigate();
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    // navigate('/error/409')
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser,currentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (access_token: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(access_token)
          const user = data.data;
          if (data.data) {
            setCurrentUser({
              id: user.id,
              username: user.username,
              email: user.email,
              created_at: user.created_at,
              role: user.role === null ? localStorage.getItem('role') : user.role,
              company: user.company,
              registration_status: {
                interests: user.registration_status.interests,
                documents: user.registration_status.documents,
                skills: user.registration_status.skills
              },
              registration_completed: user.registration_completed,
              availability_per_week: user.availability_per_week,
              requested_mentorship_period_start: user.requested_mentorship_period_start,
              requested_mentorship_period_end: user.requested_mentorship_period_end,
              interests: [],
              documents: [],
              skills: []
            })
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    if (auth && auth.access_token) {
      requestUser(auth.access_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])
  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }