import { KTIcon, KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import { ProfileModal } from 'components/admin/ProfileModal.component'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import 'react-toastify/dist/ReactToastify.css'
interface IApplicant {
  user: { id: number; name: string; role: string }
  status: string
  programs: Array<{
    id: number
    name: string
    approved: boolean
    stats: {
      active_mentee: number
      active_mentors: number
      active_trainings: number
      active_trainers: number
    }
  }>
}
const ApplicantsTable = () => {
  const [status, setStatus] = useState<string>('active')
  const [userId, setUserId] = useState<number>(0)
  const [role, setRole] = useState<string>('')
  const [loadingButtons, setLoadingButtons] = useState<number[]>([])
  const [isApplicantsLoading, setIsApplicantsLoading] = useState<boolean>(false)
  const [applicants, setApplicants] = useState<IApplicant[]>([])
  const [applicant, setApplicant] = useState<IApplicant>({
    user: { id: 0, name: '', role: '' },
    status: '',
    programs: [],
  })
  const [isActionLoading, setActionLoading] = useState<boolean>()
  const programAction = async (action: boolean, program_id: number) => {
    try {
      setActionLoading(true)
      setLoadingButtons((prevLoadingButtons) => [...prevLoadingButtons, program_id])
      const response = await axios.post(`${API_URL}/access/approve-reject`, {
        user_id: applicant?.user.id,
        program_id: program_id,
        approved: action,
      })
      if (response.data.success === true) {
        const updatedPrograms = applicant.programs.map((program) => {
          if (program.id === program_id) {
            program.approved = !program.approved
          }
          return program
        })
        setApplicant((prevApplicant) => {
          const updatedApplicant = { ...prevApplicant }
          updatedApplicant.programs = updatedApplicant.programs.map((program) => {
            if (program.id === program_id) {
              return {
                ...program,
                approved: action,
              }
            }

            return program
          })
          const allFalse = applicant?.programs?.every((program) => !program.approved)
          const ifOne = applicant?.programs?.some((program) => program.approved)
          console.log('ifone ', ifOne)
          console.log(applicant.status)
          if (allFalse) {
            updatedApplicant.status = 'dropped'
            getApplicants(status)
          } else if (ifOne && applicant.status !== 'assigned') {
            updatedApplicant.status = 'assigned'
            getApplicants(status)
          }
          console.log(updatedApplicant)
          return updatedApplicant
        })
      } else {
        console.error('Failed to set relation:', response.data.error)
      }
      setLoadingButtons((prevLoadingButtons) =>
        prevLoadingButtons.filter((buttonId) => buttonId !== program_id)
      )
      setActionLoading(true)
    } catch (err) {
    } finally {
      setActionLoading(false)
    }
  }
  const getApplicants = async (status: any) => {
    setApplicants([])
    setApplicant({ user: { id: 0, name: '', role: '' }, status: '', programs: [] })
    if (isApplicantsLoading) return;  // Zaten yükleniyor ise fonksiyonu durdur

    try {
      setIsApplicantsLoading(true);
      const response = await axios.get(`${API_URL}/access?status=${status}`);
      setApplicants(response.data.data);
    } catch (ex) {
      setApplicants([]);
    } finally {
      setIsApplicantsLoading(false);
    }
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
    getApplicants(event.target.value)
  }

  // useEffect(() => {
  //   getApplicants()
  // }, [])

  useEffect(() => {
    getApplicants(status)
  }, [status])
  return (
    <div className='row'>
      <div className='col-xl-6'>
        <div className='card card-flush h-xl-100 cursor-pointer'>
          <div className='card-header'>
            <div className='card-title flex-column'>
              <h3 className='mt-4'>Applicants</h3>
              <p className='fs-9 text-secondary fw-light'>{applicants?.length} applicants listed</p>
            </div>
            <div className='card-toolbar'>
              <select
                className='form-select form-select-solid'
                aria-label='Select example'
                value={status}
                onChange={handleStatusChange}
              >
                <option value='active'>Active</option>
                <option value='passive'>Passive</option>
              </select>
            </div>
          </div>
          <div className='card-body py-3 scroll-y' style={{ maxHeight: 700 }}>
            {isApplicantsLoading ? (
              <div className='v-100 position-relative' style={{ minHeight: '500px' }}>
                <div
                  className='page-loading position-absolute'
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span
                    className='spinner-border text-primary'
                    data-kt-app-page-loading='on'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                </div>
              </div>
            ) : (
              <div className=''>
                <table className='table table-row-bordered table-row-gray-300 gy-7 '>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th className='fw-bolder text-secondary'>Name</th>
                      <th className='fw-bolder text-secondary'>Role</th>
                      <th className='fw-bolder text-secondary'>Status</th>
                      <th className='fw-bolder text-secondary'>ACTION</th>
                    </tr>
                  </thead>

                  <tbody className='scroll-y'>
                    {applicants.map((applicantMapped) => {
                      return (
                        <tr
                          key={applicantMapped?.user?.id}
                          style={{
                            backgroundColor:
                              applicant?.user?.id === applicantMapped?.user?.id
                                ? '#E5F3F3'
                                : 'inherit',
                          }}
                        >
                          <td>
                            <div className='d-flex flex-row px-2'>
                              <div className='w-40px h-40px rounded' style={{ marginRight: 10 }}>
                                <div
                                  style={{ borderRadius: 15 }}
                                  className={`w-100 h-100 d-flex flex-center rounded bg-light-secondary text-white fs-2 fw-bolder`}
                                >
                                  <img
                                    className='w-40px h-40px rounded'
                                    alt='Logo'
                                    src={toAbsoluteUrl('/media/avatars/blank.png')}
                                  />
                                </div>
                              </div>

                              <p className='fw-bolder mt-2'>{applicantMapped?.user?.name}</p>
                            </div>
                          </td>
                          <td>
                            <p className='mt-2'>{applicantMapped?.user?.role}</p>
                          </td>
                          <td>
                            {
                              applicantMapped?.status === 'assigned' ?
                                (
                                  <span className='badge badge-primary px-5 py-2 mt-2'>
                                    Assigned
                                  </span>
                                ) : null
                            }
                            {
                              applicantMapped?.status === 'assignment_needed' ? (
                                <span className='badge badge-danger px-5 py-2 mt-2'>
                                  Check Eligibility
                                </span>
                              ) : null
                            }
                            {
                              applicantMapped?.status === 'dropped' ? (
                                <span className='badge badge-secondary px-5 py-2 mt-2 text-white'>
                                  Dropped
                                </span>
                              ) : null
                            }
                          </td>
                          <td>
                            <div className='d-flex flex-row gap-3 mt-1'>
                              <div
                                className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                                onClick={() => {
                                  setRole(applicantMapped?.user?.role)
                                  setUserId(applicantMapped?.user?.id)
                                }}
                                style={{ width: 20, height: 30 }}
                              >
                                <img
                                  className=''
                                  src={toAbsoluteUrl('/media/svg/person.svg')}
                                ></img>
                              </div>
                              <div
                                className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                onClick={() => {
                                  setApplicant(applicantMapped)
                                }}
                                style={{ width: 20, height: 30 }}
                              >
                                <KTIcon
                                  iconName='arrow-right'
                                  className='ki-duotone ki-arrow-right text-primary fs-1'
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='col-xl-6'>
        <div className='card card-flush h-xl-100 cursor-pointer'>
          <div className='card-header'>
            <div className='card-title flex-column'>
              <h3 className='mt-4'>Programs</h3>
              <p className='fs-9 text-secondary fw-light'>
                {applicant?.programs?.length} programs listed
              </p>
            </div>
          </div>
          <div className='card-body py-3 scroll-y' style={{ maxHeight: 700 }}>

            {isApplicantsLoading ? (
              <div className='v-100 position-relative' style={{ minHeight: '500px' }}>
                <div
                  className='page-loading position-absolute'
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span
                    className='spinner-border text-primary'
                    data-kt-app-page-loading='on'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                </div>
              </div>
            ) : (
              <div className=''>
                <table className='table table-row-bordered table-row-gray-300 gy-7'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th
                        className='fw-bolder text-secondary'
                        style={{ width: '15%', paddingLeft: '7px' }}
                      >
                        Name
                      </th>
                      <th className='fw-bolder text-secondary' style={{ width: '30%' }}>
                        {applicant?.user?.role === 'trainer' ? 'ACTIVE TRAINER' : 'ACTIVE MENTOR'}
                      </th>
                      <th className='fw-bolder text-secondary' style={{ width: '35%' }}>
                        {applicant?.user?.role === 'trainer' ? 'ACTIVE TRAININGS' : 'ACTIVE MENTORSHIPS'}
                      </th>
                      <th className='fw-bolder text-secondary' style={{ width: '20%' }}>
                        ACTION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicant?.programs?.map((program) => {
                      return (
                        <tr>
                          <td style={{ paddingLeft: '7px' }}>
                            <p>{program?.name}</p>
                          </td>
                          <td>
                            <p>
                              {applicant?.user?.role === 'trainer'
                                ? program?.stats?.active_trainers
                                : program?.stats?.active_mentors}{' '}
                            </p>
                          </td>
                          <td className=''>
                            <p className='m-0'>{program?.stats?.active_trainings}</p>
                          </td>
                          <td>
                            <div className='d-flex flex-row gap-3'>
                              {program?.approved ? (
                                <div
                                  className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                  onClick={() => {
                                    programAction(false, program?.id)
                                  }}
                                  style={{ width: 20, height: 30 }}
                                >
                                  {loadingButtons.includes(program?.id) ? (
                                    <div className='page-loading'>
                                      <span
                                        className='spinner-border text-primary'
                                        data-kt-app-page-loading='on'
                                        role='status'
                                      >
                                        <span className='visually-hidden'>Loading...</span>
                                      </span>
                                    </div>
                                  ) : (
                                    <img
                                      className=''
                                      src={toAbsoluteUrl('/media/svg/ShapeX.svg')}
                                    ></img>
                                  )}
                                </div>
                              ) : (
                                <div
                                  className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                  onClick={() => {
                                    programAction(true, program?.id)
                                  }}
                                  style={{ width: 20, height: 30 }}
                                >
                                  {loadingButtons.includes(program?.id) ? (
                                    <div className='page-loading'>
                                      <span
                                        className='spinner-border text-primary'
                                        data-kt-app-page-loading='on'
                                        role='status'
                                      >
                                        <span className='visually-hidden'>Loading...</span>
                                      </span>
                                    </div>
                                  ) : (
                                    <img
                                      className=''
                                      src={toAbsoluteUrl('/media/svg/Shape.svg')}
                                    ></img>
                                  )}
                                </div>
                              )}
                              {/* <Link
                                className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                to={`mentorship-task-step/1`}
                                style={{width: 20, height: 30}}
                              >
                                <img
                                  className=''
                                  src={toAbsoluteUrl('/media/svg/person.svg')}
                                ></img>
                              </Link> */}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ProfileModal id={userId} role={role} />
      <ToastContainer />
    </div>
  )
}

export default ApplicantsTable
