import { KTIcon } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import { Field, FieldArray, Form, Formik, FormikErrors, FormikHelpers } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import 'react-toastify/dist/ReactToastify.css'

import * as Yup from 'yup'

interface IStep {
  name: string
  practice: number
  moodle_hour: number
  training: number
  self_learning: number
  task_id: number
}

interface ITask {
  name: string
  training_description: string
}

export interface IProgram {
  name: string
  eqf_level: number
  ects: number
  tasks: Array<ITask>
  steps: Array<IStep>
}

export interface IProgramSubmit {
  name: string
  eqf_level: number
  ects: number
  tasks: Array<{ name: string; training_description: string; steps: Array<IStep> }>
}


const ProgramSchema = Yup.object().shape({
  name: Yup.string().test('len', 'Name should include more than 5 characters', (val) => val !== undefined && val.toString().length >= 5
  ).required(),
  eqf_level: Yup.number().min(1, 'EQF level must be greater than zero').required(),
  ects: Yup.number().min(1, 'ECTS must be greater than zero').required(),
  tasks: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Task name is required'),
        training_description: Yup.string().required('Training description is required'),
      })
    )
    .required(),
  steps: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Step name is required'),
        practice: Yup.number().min(1, "Add some practice hours, please").required("Practice hours are required"),
        moodle_hour: Yup.number().min(1, "Add some moodle hours").required("Moodle hours are required"),
        training: Yup.number().min(1, 'Add some training hours, please').required("Training hours are required"),
        self_learning: Yup.number().min(1, 'Task name is required').required("Self-learning hours are required"),
        task_id: Yup.number().min(0, 'Select a task, please').required('Select a task'),
      })
    )
    .required(),
})
const AddNewProgram = () => {
  const initialValue: IProgram = {
    name: '',
    eqf_level: 0,
    ects: 0,
    tasks: [{ name: '', training_description: '' }],
    steps: [{ moodle_hour: 1, name: '', practice: 1, self_learning: 1, task_id: -1, training: 1 }],
  }
  const [initialValueDraft, setInitialValueDraft] = useState<IProgram>(initialValue);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const saveToLocalStorage = (values) => {
    localStorage.setItem('draftProgram', JSON.stringify(values));
  };
  const submitStep = async (values: IProgram, { resetForm }: FormikHelpers<IProgram>) => {
    console.log(values)
    setIsLoading(true)
    const programSubmitData: IProgramSubmit = {
      name: values.name,
      ects: values.ects,
      eqf_level: values.eqf_level,
      tasks: values.tasks.map((task, index) => ({
        name: task.name,
        training_description: task.training_description,
        steps: values.steps.filter((step) => step.task_id == index),
      })),
    }
    await axios
      .post(`${API_URL}/programs`, programSubmitData)
      .then(() => {
        showSuccessToaster('New program was created succesfully!')
        resetForm();
      })
      .catch(() => {
        showErrorToaster(
          'Something went wrong! Please check entered information and try again please!'
        )
      })
    setIsLoading(false);

    console.log(programSubmitData)
  }
  const formikRef = useRef<any>(); // Объявление useRef

  useEffect(() => {
    const draftProgramString = localStorage.getItem('draftProgram');
    if (draftProgramString) {
      console.log(draftProgramString)
      const draftProgram = JSON.parse(draftProgramString);
      setInitialValueDraft(draftProgram);
    }
  }, []);
  useEffect(() => {
    formikRef.current.setValues(initialValueDraft); // Установить новые initialValues
  }, [initialValueDraft]);
  return (
    <div className='card card-custom mt-10'>
      <div className='card-header'>
        <h3 className='card-title w-100 mt-5'>New Program</h3>
        <p className='text-secondary fw-light'>Add new program</p>
      </div>
      <div className='card-body row'>
        <Formik validationSchema={ProgramSchema} initialValues={initialValueDraft} onSubmit={submitStep} innerRef={formikRef} // Привязка formikRef к Formik
        >
          {({ errors, touched, values, resetForm }) => (
            <Form className='d-flex flex-column h-100 pb-15' id='kt_create_account_form'>
              <div className='mb-5 row'>
                <div className='col-xl-12'>
                  <label className='form-label fs-7'>Program Name</label>

                  <Field
                    placeholder='Program Name'
                    type='text'
                    className='form-control form-control-solid'
                    name='name'
                  />
                  {errors.name && touched.name ? (
                    <div className='text-danger'>{errors.name}</div>
                  ) : null}
                </div>
              </div>

              <div className='row mb-7'>
                <div className='col-xl-6'>
                  <label className='form-label fs-7'>EQF Level</label>
                  <Field
                    placeholder='EQF Level'
                    type='number'
                    className='form-control form-control-solid'
                    name='eqf_level'
                    min='1'
                  />
                  {errors.eqf_level && touched.eqf_level ? (
                    <div className='text-danger'>{errors.eqf_level}</div>
                  ) : null}
                </div>
                <div className='col-xl-6'>
                  <label className='form-label fs-7'>ECTS</label>
                  <Field
                    placeholder='ECTS'
                    type='number'
                    className='form-control form-control-solid'
                    name='ects'
                    min='1'
                  />
                  {errors.ects && touched.ects ? (
                    <div className='text-danger'>{errors.ects}</div>
                  ) : null}
                </div>
              </div>
              <FieldArray name='tasks'>
                {({ push, remove }) => (
                  <div>
                    <div className='mb-5'>
                      <span
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          if (values.tasks.length === 0) {
                            push({ name: '', training_description: '', steps: [] })
                          }
                          const lastTaskIndex = values.tasks.length - 1
                          const lastTask = values.tasks[lastTaskIndex]
                          if (lastTask && lastTask.name && lastTask.training_description) {
                            push({ name: '', training_description: '', steps: [] })
                          }
                        }}
                      >
                        Add New Task
                      </span>
                    </div>
                    {values.tasks.map((_, index) => (
                      <div key={index} className='mb-5 row'>
                        <div className='col-xl-5'>
                          <label className='form-label fs-7'>Task Name</label>
                          <Field
                            placeholder={`Task Name ${index + 1}`}
                            type='text'
                            className='form-control form-control-solid'
                            name={`tasks.${index}.name`}
                          />
                          {errors.tasks &&
                            errors.tasks[index] &&
                            (errors.tasks[index] as FormikErrors<ITask>).name &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.tasks[index] as FormikErrors<ITask>).name}
                              </div>
                            )}
                        </div>
                        <div className='col-xl-5'>
                          <label className='form-label fs-7'>Training Description</label>
                          <Field
                            placeholder={`Training Description ${index + 1}`}
                            type='text'
                            className='form-control form-control-solid'
                            name={`tasks.${index}.training_description`}
                          />
                          {errors.tasks &&
                            errors.tasks[index] &&
                            (errors.tasks[index] as FormikErrors<ITask>).training_description &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.tasks[index] as FormikErrors<ITask>).training_description}
                              </div>
                            )}
                        </div>
                        <div
                          className='col-xl-1 btn btn-sm btn-light px-1 py-1 mx-3 text-center d-flex align-items-center justify-content-center'
                          style={{ alignSelf: 'end', height: '40px', width: '50px' }}
                          onClick={() => remove(index)}
                        >
                          <KTIcon iconName='cross' className='fs-2' />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <FieldArray name='steps'>
                {({ push, remove }) => (
                  <div>
                    <div className='mb-5'>
                      <span
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          if (values.steps.length === 0) {
                            push({
                              name: '',
                              practice: 0,
                              moodle_hour: 0,
                              training: 0,
                              self_learning: 0,
                              task_id: -1,
                            })
                          }
                          const lastStepIndex = values.steps.length - 1
                          const lastStep = values.steps[lastStepIndex]
                          console.log(lastStep)
                          if (
                            lastStep &&
                            lastStep.name &&
                            lastStep.moodle_hour >= 0 &&
                            lastStep.practice >= 0 &&
                            lastStep.self_learning >= 0 &&
                            lastStep.task_id >= 0 &&
                            lastStep.training >= 0
                          ) {
                            push({
                              name: '',
                              practice: 0,
                              moodle_hour: 0,
                              training: 0,
                              self_learning: 0,
                              task_id: -1,
                            })
                          }
                        }}
                      >
                        Add New Step
                      </span>
                    </div>
                    {values.steps.map((step, index) => (
                      <div className='row mb-10'>
                        <div className='col-xl-2'>
                          <label className='form-label fs-7'>Step Name</label>
                          <Field
                            className='form-control form-control-solid'
                            placeholder='Step Name'
                            name={`steps.${index}.name`}
                          ></Field>
                          {errors.steps &&
                            errors.steps[index] &&
                            (errors.steps[index] as FormikErrors<IStep>).name &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.steps[index] as FormikErrors<IStep>).name}
                              </div>
                            )}
                        </div>
                        <div className='col-xl-2'>
                          <label className='form-label fs-7'>Practice Hour</label>
                          <Field
                            className='form-control form-control-solid'
                            placeholder='Practice Hour'
                            min='1'
                            type='number'
                            name={`steps.${index}.practice`}
                          ></Field>
                          {errors.steps &&
                            errors.steps[index] &&
                            (errors.steps[index] as FormikErrors<IStep>).practice &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.steps[index] as FormikErrors<IStep>).practice}
                              </div>
                            )}
                        </div>
                        <div className='col-xl-2'>
                          <label className='form-label fs-7'>Moodle hour</label>
                          <Field
                            className='form-control form-control-solid'
                            placeholder='Moodle hours'
                            min='1'
                            type='number'
                            name={`steps.${index}.moodle_hour`}
                          ></Field>
                          {errors.steps &&
                            errors.steps[index] &&
                            (errors.steps[index] as FormikErrors<IStep>).moodle_hour &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.steps[index] as FormikErrors<IStep>).moodle_hour}
                              </div>
                            )}
                        </div>
                        <div className='col-xl-2'>
                          <label className='form-label fs-7'>Training hour</label>
                          <Field
                            className='form-control form-control-solid'
                            placeholder='Training hour'
                            min='1'
                            type='number'
                            name={`steps.${index}.training`}
                          ></Field>
                          {errors.steps &&
                            errors.steps[index] &&
                            (errors.steps[index] as FormikErrors<IStep>).training &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.steps[index] as FormikErrors<IStep>).training}
                              </div>
                            )}
                        </div>
                        <div className='col-xl-2'>
                          <label className='form-label fs-7'>Self-learning Hour</label>
                          <Field
                            className='form-control form-control-solid'
                            placeholder='Self-learning Hour'
                            min='1'
                            type='number'
                            name={`steps.${index}.self_learning`}
                          ></Field>
                          {errors.steps &&
                            errors.steps[index] &&
                            (errors.steps[index] as FormikErrors<IStep>).self_learning &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.steps[index] as FormikErrors<IStep>).self_learning}
                              </div>
                            )}
                        </div>
                        <div className='col-xl-2'>
                          <label className='form-label fs-7'>Task</label>
                          <Field
                            as='select'
                            className='form-control form-control-solid'
                            name={`steps.${index}.task_id`}
                          >
                            <option value={-1}>Select Task</option>
                            {values.tasks.map((task, index) => {
                              return (
                                task.name !== '' && (
                                  <option key={index} value={index}>
                                    {task.name}
                                  </option>
                                )
                              )
                            })}
                          </Field>
                          {errors.steps &&
                            errors.steps[index] &&
                            (errors.steps[index] as FormikErrors<IStep>).task_id &&
                            touched.tasks &&
                            touched.tasks[index] && (
                              <div className='text-danger'>
                                {(errors.steps[index] as FormikErrors<IStep>).task_id}
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <div className='card-footer d-flex justify-content-end gap-5'>
                <a className='btn btn-light btn-sm px-10' onClick={() => { saveToLocalStorage(values) }}>Save as draft</a>
                <button className='btn btn-primary btn-sm px-10' type='submit'>
                  {isLoading ? 'Saving...' : 'Save'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  )
}

export default AddNewProgram
