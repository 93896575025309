import {API_URL} from 'app/consts/consts'
import {ICreateAccount} from 'app/modules/wizards/components/CreateAccountWizardHelper'
import axios, {AxiosResponse} from 'axios'
import {IInterest, ISkill} from '../types/registerTypes'
import fileDownload from 'js-file-download'

interface IToken {
  id_token: string
  access_token: string
  refresh_token: string
  expires_in: number
  token_type: string
}

export const initUser = async (values: ICreateAccount, role: string) => {
  try {
    const response = await axios.post(`${API_URL}/account/init`, {
      role: role,
      availability_per_week: values.availability_per_week,
      requested_mentorship_period_start: values.requested_mentorship_period_start,
      requested_mentorship_period_end: values.requested_mentorship_period_end,
    })
    console.log(response)
    if (!response) {
      return null
    }
    localStorage.setItem('is_init', 'true')
    return response.data
  } catch (err) {
    console.log(err)
    return null
  }
}

export const updateProfile = async (values: ICreateAccount) => {
  try {
    const response = await axios.post(`${API_URL}/account/update-profile`, {
      availability_per_week: values.availability_per_week,
      requested_mentorship_period_start: values.requested_mentorship_period_start,
      requested_mentorship_period_end: values.requested_mentorship_period_end,
      company: values.company
    })
    if (!response) {
      return null
    }

    return response.data
  } catch (err) {
    return null
  }
}

export const setInterests = async (values: any) => {
  try {
    const response = await axios.post(`${API_URL}/account/set-interests`, {
      interests: values,
    })
    if (!response) {
      return null
    }
    return response.data
  } catch (err) {
    console.log(err)
    return null
  }
}

export const addDocument = async (file: File, fileId: string) => {
  try {
    const formData = new FormData()
    formData.append('file_id', fileId)
    formData.append('file', file)
    const response = await axios.post(`${API_URL}/account/set-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    return
  }
}

export const setSkills = async (skills: ISkill[]) => {
  try {
    const response = await axios.post(`${API_URL}/account/set-skills`, {
      skills: skills,
    })
    console.log('re', response)
    if (!response) {
      return
    }
    return response.data
  } catch (err) {
    return
  }
}

export const loadFilesList = async (role: string) => {
  try {
    const response = await axios.get(`${API_URL}/files?role=${role}`)
    if(!response || !response.data) {
      return null;
    }

    return response
  } catch (err) {
    return null;
  }
}

export const loadSkillsList = async (role: string) => {
  try {
    const response = await axios.get(`${API_URL}/skills?role=${role}`)
    return response.data
  } catch (err) {
    return null
  }
}

export const loadInterestsList = async () => {
  try {
    const response = await axios.get(`${API_URL}/interests`)
    return response.data
  } catch (err) {
    return
  }
}

export const loadFilledList = async (id: number) => {
  try{
    const response = await axios.get(`${API_URL}/users/${id}/interests`)
    return response.data
  } catch(err) {
    return
  }
}

export const loadFilledDocs = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}/documents`)
    return response.data
  } catch (err) {
    return
  }
}

export const getFileDownloadLink = async (file_id:number | null)=>{
  if(!file_id){
    return;
  }
  const response = await axios.get(`${API_URL}/files/${file_id}/download`, {responseType: 'arraybuffer'});
  fileDownload(response.data, 'upload.pdf')
}

export const loadFilledSkills= async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}/users/${id}/skills`)
    return response.data
  } catch (err) {
    return
  }
}

export const downloadActivity=async(file_id: string) => {
  try {
    const response = await axios.get(`${API_URL}/submission/${file_id}/get-file`);
    return response.data.url
  } catch {
    return
  }
}

export const sendFile = async (file: File, fileId: string) => {
  try {
    const formData = new FormData()
    formData.append('file_id', fileId)
    formData.append('file', file)
    console.log(formData.get('file'))
    const response = await axios.post(`${API_URL}/account/add-document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    console.log(response.data)
    return response.data
  } catch (error) {
    console.error(error)
  }
}

