import { FC } from 'react'
import { Routes, Route, BrowserRouter, } from 'react-router-dom'
import { AuthPage, useAuth } from '../app/modules/auth'
import { App } from '../App'
import AdminRoutes from './AdminRoutes'
import { RegisterPage } from 'app/modules/register/RegisterPage'
import { ErrorsPage } from 'app/modules/errors/ErrorsPage'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  console.log(currentUser)
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {currentUser?.role === 'admin' ? (
            <>
              <Route path='/*' element={<AdminRoutes />} />
            </>
          ) : (
            <>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='*' element={<RegisterPage />} />
              <Route path='auth/*' element={<AuthPage />} />
            </>
          )}

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
