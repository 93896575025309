import React from 'react'
import ApplicantsTable from '../components/ApplicantsTable.component'

const AccessMain = () => {
  return (
    <div className='row'>
        <ApplicantsTable/>
    </div>
  )
}

export default AccessMain