import {toAbsoluteUrl} from '_metronic/helpers'
import {API_URL} from 'app/consts/consts'
import axios from 'axios'
import clsx from 'clsx'
import React, {useEffect, useMemo, useState} from 'react'
import {debounce} from 'lodash'

export interface IChat {
  conversation_id?: number | null
  created_at?: string
  last_message: {id: number; body: string; created_at: string} | undefined
  participant: {id: number; name: string; role: string; email: string}
  updated_at?: string
}
interface Props {
  onSelectChat: (chat: IChat) => void
}
const UserList: React.FC<Props> = ({onSelectChat}) => {
  const [chats, setChats] = useState<IChat[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filteredChats, setFilteredChats] = useState<IChat[]>([])
  const handleSearch = useMemo(
    () =>
      debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
          console.log('empty')
          getChats()
        } else {
          console.log('not empty')
          const response = await axios.get(`${API_URL}/users?name=${event.target.value}`)
          setFilteredChats(() => {
            console.log(
              response?.data?.data?.map((user) => {
                return {
                  last_message: undefined,
                  participant: {
                    email: user.email,
                    id: user.id,
                    name: user.username,
                    role: user.role,
                  },
                  conversation_id: null,
                  created_at: user.created_at,
                  updated_at: user.created_at,
                } as IChat
              }) as IChat[]
            )
            return response?.data?.data?.map((user) => {
              return {
                last_message: undefined,
                participant: {email: user.email, id: user.id, name: user.username, role: user.role},
                conversation_id: null,
                created_at: user.created_at,
                updated_at: user.created_at,
              } as IChat
            }) as IChat[]
          })
          console.log(response.data)
        }
      }, 300),
    []
  )

  // const filteredChats = chats.filter((chat) => chat.participant.name.toLowerCase().includes(searchTerm.toLowerCase()))

  const getChats = async () => {
    setIsLoading(true)
    axios
      .get(`${API_URL}/conversations`)
      .then((response) => {
        setChats(response.data.data)
        setFilteredChats(response.data.data)

      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const handleChatSelect = (chat: IChat) => {
    console.log(chat)
    onSelectChat(chat)
  }
  useEffect(() => {
    getChats()
  }, [])
  return (
    <div className='col-xl-5 '>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title w-100'>
            <input
              className='form-control form-control-solid h-50px'
              placeholder='Search'
              onChange={handleSearch}
            ></input>
          </h3>
        </div>
        <div className='card-body card-scroll-y  '>
          {isLoading ? (
            <div className='v-100 position-relative h-200px' style={{minHeight: '86%'}}>
              <div
                className='page-loading position-absolute'
                style={{
                  top: '50%',
                  left: '50%',
                }}
              >
                <span
                  className='spinner-border text-primary'
                  data-kt-app-page-loading='on'
                  role='status'
                >
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            </div>
          ) : (
            <div className='users'>
              {filteredChats.map((chat) => (
                <div
                  key={chat.conversation_id}
                  className='d-flex justify-content-between mb-4'
                  style={{alignItems: 'center'}}
                >
                  <div className='d-flex flex-row align-items-center'>
                    <div
                      className='w-40px h-40px rounded align-self-start'
                      style={{marginRight: 10}}
                    >
                      <div
                        style={{borderRadius: 15}}
                        className={`w-100 h-100 d-flex flex-center rounded bg-light-secondary text-white fs-2 fw-bolder`}
                      >
                        <img
                          className='w-40px h-40px rounded align-self-start'
                          alt='Logo'
                          src={toAbsoluteUrl('/media/avatars/blank.png')}
                        />
                      </div>
                    </div>
                    <div>
                      <p
                        className='p-0 m-0 fw-bolder text-hover-primary cursor-pointer fs-5'
                        onClick={(event) => {
                          event.stopPropagation()
                          handleChatSelect(chat)
                        }}
                      >
                        {chat.participant.name}
                      </p>
                      <p className='fs-8' style={{maxWidth: 195}}>
                        {chat?.participant?.email}
                      </p>
                    </div>
                  </div>
                  {chat?.participant?.role ? (
                    <div className='card-toolbar'>
                      <span
                        className={clsx(
                          `badge badge-${
                            chat?.participant?.role === 'trainer' ? 'danger' : 'primary'
                          } px-5 py-3`
                        )}
                      >
                        {chat?.participant?.role}
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserList
