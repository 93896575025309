import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActiveSub, KTIcon} from '../../../helpers'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  textColor?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  textColor,
}) => {
  const {pathname} = useLocation()

  return (
    <div className='menu-item'>
      <Link
        style={{borderRadius: '0.475rem 0.475rem 0 0', color: textColor ? textColor : ''}}
        className={clsx('menu-link py-3', {
          active: checkIsActiveSub(pathname, to),
        })}
        to={to}
      >
        <span
          style={{color: textColor ? textColor : ''}}
          className='menu-title'
        >
          {title}
        </span>
      </Link>
    </div>
  )
}

export {MenuItem}
