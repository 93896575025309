import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick

import moment from 'moment'
import axios, { all } from 'axios'
import { API_URL } from 'app/consts/consts'
import { useEffect, useState } from 'react'
import { useAuth } from 'app/modules/auth'
import { KTSVG } from '_metronic/helpers'

export interface IProgram {
  id: number
  name: string
  tasks_count: number
  steps_count: number
  eqf_level: number
  ects: number
  created_at: string
}
interface Params {
  start_date: string
  program_id: number
  event_type: string
  view_as: string
  end_date: string
}
const MainCalendar = () => {
  const [events, setEvents] = useState<any>([])
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()
  const [programs, setPrograms] = useState<IProgram[]>([])
  const [params, setParams] = useState<string>()
  const [programId, setProgramId] = useState<number | null>(0)
  const [users, setUsers] = useState<Array<{ id: number; username: string }>>([])
  const [selectedEventType, setSelectedEventType] = useState<string | null>(null)
  const [selectedPerson, setSelectedPerson] = useState<number | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null)
  const handleDateClick = (arg: { dateStr: any }) => {
    alert(arg.dateStr)
  }
  var todayDate = moment().startOf('day')
  var YM = todayDate.format('YYYY-MM')

  const eventClick = (info: any) => {
    // alert('Event: ' + info.event.title)
    // alert('Event: ' + info.event?.extendedProps.description)

    setSelectedEvent(info.event)
    document.getElementById('kt_modal_training_button')?.click()
  }
  const firstDateOfYear = moment().startOf('year').format('YYYY-MM-DD')
  const lastDateOfYear = moment().endOf('year').format('YYYY-MM-DD')

  const eventTypeColorResolver = (eventType: string) => {
    switch (eventType) {
      case 'training':
        return '#00A3FF'
      case 'teamwork':
        return '#FFA800'
      case 'meeting':
        return '#6C7293'
      default:
        return 'purple'
    }
  }


  const getCalendarEvents = async () => {
    setLoading(true)
    try {
      const params: any = {
        start_date: firstDateOfYear,
        end_date: lastDateOfYear,
      }
      if (programId) params.program_id = programId
      if (selectedEventType) params.event_type = selectedEventType
      if (selectedPerson) params.view_as = selectedPerson
      if (selectedStatus) params.status = selectedStatus
      console.log(params)
      await axios
        .get(`${API_URL}/calendar`, {
          params,
        })
        .then((response) => {
          console.log(response.data)

          setEvents(
            Object.keys(response.data)
              .map((key) => {
                return response.data[key].map((event) => {
                  const time = new Date(event.date).toISOString().split('T')[1].split('Z')[0]
                  const add40Minutes = moment(time, 'HH:mm').add(40, 'minutes').format('HH:mm')
                  return {
                    title: event.title,
                    eventType: event.event_type,
                    color: eventTypeColorResolver(event.event_type),
                    start: new Date(event.date).toISOString(),
                    end:
                      new Date(event.date).toISOString().split('T')[0] + 'T' + add40Minutes + 'Z',
                    description: event.title,
                    location: event.location,
                    data: event,
                    allDay: true,
                  }
                })
              })
              .flat()
          )
        })

      const programs = await axios.get(`${API_URL}/programs`)
      setPrograms(programs?.data?.data)
      const users = await axios.get(`${API_URL}/users`)
      setUsers(users.data.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handleSelectedStatus = (event) => {
    const status = event.target.value
    if (status) {
      setSelectedStatus(status)
    }
  }
  const handleProgramChange = (event) => {
    const programId = parseInt(event.target.value)
    setProgramId(programId !== 0 ? programId : null)
  }
  const handleUserChange = (event) => {
    const userId = parseInt(event.target.value)
    console.log(event.target.value)
    setSelectedPerson(userId !== 0 ? userId : null)
  }
  const handleTypeChange = (event) => {
    if (!event.target.value) {
      setSelectedEventType(null)
    }
    setSelectedEventType(event.target.value)
  }
  useEffect(() => {
    getCalendarEvents()
  }, [])
  useEffect(() => {
    getCalendarEvents()
  }, [programId, selectedEventType, selectedPerson, selectedStatus])
  return (
    <div>
      <TrainingViewModal event={selectedEvent} getCalendar={getCalendarEvents} />
      <div className='w-100 d-flex flex-row justify-content-between mb-9 mt-10'>
        <h1>Calendar</h1>
      </div>
      <div className='row mb-5'>
        <div className='col-xl-2'>
          <select
            className='form-select'
            aria-label='Select example'
            onChange={handleProgramChange}
          >
            <option value={0}>Select Program</option>
            {programs?.map((program) => {
              return <option value={program.id}>{program.name}</option>
            })}
          </select>
        </div>
        <div className='col-xl-2'>
          <select className='form-select' aria-label='Select example' onChange={handleTypeChange}>
            <option value={''}>Select Type</option>
            <option value={'training'}>Training</option>
            <option value={'teamwork'}>Teamwork</option>
          </select>
        </div>
        <div className='col-xl-2'>
          <select className='form-select' aria-label='Select example' onChange={handleUserChange}>
            <option value={0}>Select Person</option>
            {users?.map((user) => {
              return <option value={user.id}>{user.username}</option>
            })}
          </select>
        </div>
        <div className='col-xl-2'>
          <select className='form-select' aria-label='Select example' onChange={handleSelectedStatus}>
            <option>Select Status</option>
            <option value={'cancelled'}>Cancelled</option>
            <option value={'active'}>Confirmed</option>
          </select>
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <FullCalendar
            eventClick={eventClick}
            plugins={[dayGridPlugin, timeGridPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              end: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            navLinks={true} // can click day/week names to navigate views
            selectMirror={true}
            editable={true}
            dayMaxEvents={true} // allow "more" link when too many events
            events={events}
          />
        </div>
      </div>
    </div>
  )
}



const TrainingViewModal = ({ event, getCalendar }) => {
  const currentUser = useAuth().currentUser

  const eventTypeColorResolver = (eventType: string) => {
    switch (eventType) {
      case 'training':
        return {
          subtitle: 'Training',
          type: 'Trainer:'
        }
      case 'teamwork':
        return {
          subtitle: 'Teamwork',
          type: 'Mentee:'
        }
      case 'meeting':
        return {
          subtitle: 'Meeting',
          type: 'User:'
        }
      default:
        return {
          subtitle: 'Event',
          type: 'User:'
        }
    }
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_training'
        id='kt_modal_training_button'
        style={{ display: 'none' }}
      ></button>
      <div className='modal fade' tabIndex={-1} id='kt_modal_training'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{event?.title}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
                id='close_modal_training'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
              <p
                className='badge badge-light-dark mx-2'
              >
                {eventTypeColorResolver(event?.extendedProps?.data?.event_type).subtitle}
              </p>
            </div>
            <div className='modal-body'>
              <div>
                <span className='fw-bold'>
                  {eventTypeColorResolver(event?.extendedProps?.data?.event_type).type}
                </span>
                {event?.extendedProps?.data?.owner_user?.name}
              </div>
              <div>
                <span className='badge badge-success mx-2'>Scheduled</span>
                <span className='badge badge-light-dark mx-2'>
                  {moment(event?.start).format('DD MMMM YYYY')}
                </span>
                <span className='badge badge-light-dark mx-2'>
                  {
                    event?.start?.toISOString().split('T')[1].split(':')[0] + ':' + event?.start?.toISOString().split('T')[1].split(':')[1]
                  }
                  {' - '}
                  {
                    moment(

                      event?.start?.toISOString().split('T')[0] + ' ' + event?.start?.toISOString().split('T')[1].split(':')[0] +
                      ':' +
                      event?.start?.toISOString().split('T')[1].split(':')[1]
                    ).add(40, 'minutes').format('HH:mm')

                  }
                </span>
                <span className='badge badge-light-dark mx-2'>Level 5</span>
                <span className='badge badge-light-dark'>25 pax</span>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const TrainingViewActionModal = ({ event }) => {
  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_training'
        id='kt_modal_training_button'
        style={{ display: 'none' }}
      ></button>
      <div className='modal fade' tabIndex={-1} id='kt_modal_training'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{event?.title}</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div>
                <span className='fw-bold'>Trainer:</span>{' '}
                {event?.extendedProps?.data?.owner_user?.name}
              </div>
              <div>
                <span className='badge badge-success mx-2'>Scheduled</span>
                <span className='badge badge-light-dark mx-2'>
                  {moment(event?.start).format('DD MMMM YYYY')}
                </span>
                <span className='badge badge-light-dark mx-2'>
                  {moment(event?.start).format('HH:mm')}
                </span>
                <span className='badge badge-light-dark'>Level 5</span>
                <span className='badge badge-light-dark'>25 pax</span>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button type='button' className='btn btn-danger' data-bs-dismiss='modal'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainCalendar
