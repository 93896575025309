import React, {useEffect, useState} from 'react'
import InstructionalCard from '../components/InstructionalCard.component'
import axios from 'axios'
import {API_URL} from 'app/consts/consts'
import {showErrorToaster} from 'utils/toasters'

export interface IDesign {
  id: number
  name: string
  task_count: number | null
  created_at: string
  deleted_at: string | null
  tasks_count: number
  eqf_level: number
  vacant_tasks: number
}

const InstructionalDesign = () => {
  const [designs, setDesigns] = useState<IDesign[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getDesigns = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/programs/instructional-design`)
      setDesigns(response.data.data)
      setIsLoading(false)
    } catch (err) {
      setDesigns([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getDesigns()
  }, [])
  return (
    <div className='mt-10'>
      <div className='row g-5 g-xl-8 '>
        {isLoading ? (
          <div className='position-relative' style={{minHeight: '70vh'}}>
            <div
              className='page-loading position-absolute'
              style={{
                top: '50%',
                left: '50%',
              }}
            >
              <span
                className='spinner-border text-primary'
                data-kt-app-page-loading='on'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </span>
            </div>
          </div>
        ) : (
          designs?.map((item, index) => (
            <InstructionalCard
              key={item.id}
              id={item.id}
              name={item.name}
              eqf_level={item.eqf_level}
              total_tasks={item.tasks_count}
              vacant_tasks={item.vacant_tasks}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default InstructionalDesign
