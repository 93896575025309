import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'
import {useAuth} from 'app/modules/auth'

export function MenuInner() {
  const {currentUser} = useAuth()
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Dashboard' to='/dashboard' />
      <MenuInnerWithSub
        title='Program'
        to='/program'
        icon='element-plus'
        hasArrow={true}
        menuPlacement='right-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='/program/all-programs' title='All Programs' textColor={'#80808F'}/>
        <MenuItem
          to='/program/instructional-design'
          title='Instructional Design'
          textColor={'#80808F'}
        />
        <MenuItem to='/program/training-authorization' title='Training Authorization' textColor='#80808F'/>
      </MenuInnerWithSub>


      <MenuItem title='Matching' to='/matching' />
      <MenuInnerWithSub
        title='Calendar'
        to='/calendar'
        icon='element-plus'
        hasArrow={true}
        menuPlacement='right-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='/calendar/main' title='Calendar' textColor={'#80808F'}/>
        <MenuItem
          to='/calendar/planning'
          title='Planning Calendar'
          textColor={'#80808F'}
        />
      </MenuInnerWithSub>
      <MenuItem title='Access' to='/access' />
      <MenuItem title='Chat' to='/chat' />
    </>
  )
}
