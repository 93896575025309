import React from 'react'
import { TrainingAuthorizationTable } from '../components/TrainingAuthorizationTable.component'

export const TrainingAuthorization = () => {
  return (
    <div className='mt-10'>
        <TrainingAuthorizationTable/>
    </div>
  )
}
