import { KTIcon, KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
interface IProgram {
  id: number,
  name: string,
  total_match: number,
  active_match: number,
  total_mentors: number,
  pending_match: number,
  match_required: boolean,
  status: string;
}
const MatchingProgramTable = () => {
  const [programs, setPrograms] = useState<IProgram[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getPrograms = async () => {
    setIsLoading(true)
    const response = await axios.get(`${API_URL}/matching`)
    setPrograms(response.data.data)
    setIsLoading(false)
  }
  useEffect(() => {
    getPrograms();
  }, [])
  return (
    <div className='card card-flush h-xl-100 cursor-pointer'>

      <div className='card-header'>
        <div className='card-title flex-column'>
          <h3 className='mt-4'>Programs</h3>
          <p className='fs-9 text-secondary fw-light'>{programs?.length} programs listed</p>
        </div>
      </div>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='v-100 position-relative' style={{ minHeight: '500px' }}>
            <div
              className='page-loading position-absolute'
              style={{
                top: '50%',
                left: '50%',
              }}
            >
              <span
                className='spinner-border text-primary'
                data-kt-app-page-loading='on'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </span>
            </div>
          </div>
        ) : (
          <div className='' >
            <table className='table table-row-bordered table-row-gray-300 gy-7' >
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className=' text-secondary'>
                    Name
                  </th>
                  <th className='text-secondary'>Total Match</th>
                  <th className='text-secondary'>Active Match</th>
                  <th className='text-secondary'>Pending Match</th>
                  <th className='text-secondary'>Total Mentors</th>
                  <th className='text-secondary'>STATUS</th>
                  <th className='text-secondary'>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {programs.map((program) => (
                  <tr key={program.id}>
                    <td className='fw-bolder ' >{program.name}</td>
                    <td className='fw-bolder '>{program.total_match}</td>
                    <td className='fw-bolder '>{program.active_match}</td>
                    <td className='fw-bolder '>{program.pending_match}</td>
                    <td className='fw-bolder '>{program.total_mentors}</td>
                    <td>
                      {program.match_required && <span className='badge badge-danger px-5 py-2'>Match Required</span>
                      }
                    </td>
                    <td>
                      <Link to={`${program.id}`} className='btn btn-sm btn-light d-flex justify-content-center align-items-center w-40px h-30px'>
                        <img className='w-20px h-20px' src={toAbsoluteUrl('/media/svg/Right-2.svg')}></img>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>)}
      </div>
    </div>
  )
}

export default MatchingProgramTable
