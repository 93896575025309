import { KTIcon, toAbsoluteUrl } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import { InstructionalDesignApplyModal } from 'components/admin/InstructionalDesignApplyModal.component'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import 'react-toastify/dist/ReactToastify.css'
import { IDesign } from './InstructionalDesign.page'
import { TaskCollapseBox } from 'components/admin/TaskCollapseBox.component'
interface IContentApplication {
  admin_can_approve_reject: boolean
  motivation_letter: string
  approved: boolean
  applicant_user: {
    id: number
    name: string
  }
}

export interface ITask {
  id: number
  name: string
  training_description: string
  status: string
  content_application: IContentApplication
  steps: Array<any>
  program: { id: number; name: string }
  trainer_can_apply: boolean
}
export function getStatusText(status: string) {
  switch (status) {
    case 'todo':
      return { color: '#FFF5E8', text: 'Yet To Start', textColor: '#FFA800' }
    case 'in_progress':
      return { color: '#EEF6FF', text: 'In Progress ', textColor: '#3699FF' }
    case 'canceled':
      return { color: '#FFE2E5', text: 'Canceled', textColor: 'red' }
    case 'completed':
      return { color: '#E8FFF3', text: 'Completed', textColor: '#50CD89' }
    case 'action_required':
      return { color: '#F64E60', text: 'Action Required', textColor: '#FFFFFF' }
    case 'reply_submission_required':
      return { color: '#f1416c', text: 'Action Required', textColor: '#FFFFFF' }

    default:
      return { color: '#EEF6FF', text: 'In Progress', textColor: '#3699FF' }
  }
}
const InstructionalDesignTasks = () => {
  const { programId } = useParams<{ programId: string }>() // Add type for useParams
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tasks, setTasks] = useState<ITask[]>([])
  const [isOpen, setIsOpen] = useState<Boolean>(true)
  const [taskId, setTaskId] = useState<number>(0)
  const [task, setTask] = useState<ITask>()
  const [userId, setUserId] = useState<number>(0)
  const [designs, setDesigns] = useState<IDesign[]>()
  const [program, setProgram] = useState<any>({})
  const toggleCard = () => {
    setIsOpen(!isOpen)
  }
  const toDoTasks = useMemo(() => {
    if (tasks.length > 0) {
      return tasks?.filter(
        (task) => task?.status === 'waiting_task' || task?.status === 'need_action'
      )
    }
  }, [tasks])
  const inProgressTasks = useMemo(() => {
    if (tasks.length > 0) {
      return tasks?.filter((task) => task?.status === 'in_progress')
    }
  }, [tasks])
  const completedTasks = useMemo(() => {
    console.log(tasks)
    if (tasks.length > 0) {
      return tasks?.filter((task) => task?.status === 'completed')
    }
  }, [tasks])

  const getProgramInfo = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        `${API_URL}/programs/instructional-design/${programId}/tasks`
      )
      setTasks(response.data.data.tasks)
      setProgram(response.data.data.program)
    } catch (error) {
      setTasks([])
      console.error('Error fetching program info:', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getProgramInfo()
  }, [programId])

  return (
    <div className='mt-10'>
      {isLoading ? (
        <div className='v-100 position-relative' style={{ minHeight: '80vh' }}>
          <div
            className='page-loading position-absolute'
            style={{
              top: '50%',
              left: '50%',
            }}
          >
            <span
              className='spinner-border text-primary'
              data-kt-app-page-loading='on'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </span>
          </div>
        </div>
      ) : (
        <div className='tab-content'>
          <h1 className='mb-1 fs-1'>{program.name}</h1>
          <h2 className='mb-5 mt-10'>
            Instructional Design Tasks
          </h2>
          <div id='kt_project_targets_card_pane' className='tab-pane fade show active'>
            <div className='row g-9'>
              <div className='col-md-4 col-lg-12 col-xl-4'>
                <div className='mb-7'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold fs-4 w-100'>
                      Yet to start
                      <div className='h-3px w-100 bg-warning'></div>
                    </div>
                  </div>
                </div>
                {toDoTasks &&
                  toDoTasks?.map((item, index) => (
                    <div className='card shadow-sm mb-3' key={index}>
                      <a
                        className='card-header collapsible cursor-pointer rotate collapsed d-flex flex-column'
                        href={`#1`}
                      >
                        <div className='d-flex flex-row align-items-center py-5'>
                          <h1 className='card-title fw-bold lh-base'>{item.name}</h1>
                        </div>
                        {item?.content_application &&
                          item?.content_application?.admin_can_approve_reject && (
                            <div className='card-footer py-3 px-0'>
                              <button
                                type='button'
                                className='btn btn-lg btn-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_instuctional_design'
                                onClick={() => setTask(item)}
                              >
                                View
                              </button>
                            </div>
                          )}
                      </a>
                    </div>
                  ))}
              </div>

              <div className='col-md-4 col-lg-12 col-xl-4'>
                <div className='mb-7'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold fs-4 w-100'>
                      In Progress
                      <div className='h-3px w-100' style={{ backgroundColor: '#009EF6' }}></div>
                    </div>
                  </div>
                </div>
                <div className='card shadow-sm mb-3'></div>

                {inProgressTasks &&
                  inProgressTasks?.map((item, index) => (
                    <div>
                      <TaskCollapseBox index={`${index}`} task={item} isProgress={true} />
                    </div>
                  ))}
              </div>

              <div className='col-md-4 col-lg-12 col-xl-4'>
                <div className='mb-7'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-bold fs-4 w-100'>
                      Completed
                      <div className='h-3px w-100' style={{ backgroundColor: '#50CD89' }}></div>
                    </div>
                  </div>
                </div>
                {completedTasks &&
                  completedTasks?.map((item, index) => (
                    <div className='card shadow-sm mb-3' key={index}>
                      <a
                        className='card-header collapsible cursor-pointer rotate collapsed d-flex flex-column'
                        href={`#1`}
                      >
                        <div className='d-flex flex-row align-items-center py-5'>
                          <h1 className='card-title fw-bold lh-base'>{item.name}</h1>
                        </div>
                        {item?.content_application &&
                          item?.content_application?.admin_can_approve_reject && (
                            <div className='card-footer py-3 px-0'>
                              <button
                                type='button'
                                className='btn btn-lg btn-primary'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_instuctional_design'
                                onClick={() => {
                                  setTaskId(item?.id)
                                  setUserId(item?.content_application?.applicant_user.id)
                                }}
                              >
                                View
                              </button>
                            </div>
                          )}
                      </a>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <InstructionalDesignApplyModal
        task={task}
        taskId={taskId}
        userId={userId}
        showErrorToaster={showErrorToaster}
        showSuccessToaster={showSuccessToaster}
        getProgramInfo={getProgramInfo}
      />
      <ToastContainer />
    </div>
  )
}

export default InstructionalDesignTasks
