import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import StepActivity from 'components/program/StepActivity.component'
import LoadingSpinner from 'components/LoadingSpinner.component'
import { Step } from 'types/types'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { API_URL } from 'app/consts/consts'
import { showErrorToaster } from 'utils/toasters'
import { toAbsoluteUrl } from '_metronic/helpers'
import { countDays } from 'utils/stringDateFunctions'
import { WaitingAlert } from 'components/WaitingAlert.component'
import { ApprovedAlert } from 'components/ApprovedAlert.compoenent'
import { RejectedAlert } from 'components/RejectedAlert.component'
import { ReviewSubmission } from '../components/ReviewSubmission.component'
import clsx from 'clsx'
interface IStep {
  id: number
  name: string
  description: string
  task_id: number
  moodle_url: string
  step_code: string
  moodle_number: number
  practice: number
  self_learning: number
  duration: number
}
export interface ISubmission {
  id: number
  activities: Array<any>
  step_id: number
  content: Array<string>
  approved: boolean
  user: { id: number; name: string }
  uploads: Array<{
    path: string
    download_url: string
  }>
}
interface ITask {
  id: number
  name: string
  program_id: number
  content_application: { applicant_user: { id: number } }
}
interface IProgram {
  id: number
  name: string
}

export const InstructionalDesignTaskApprovement = () => {
  const { stepId, programId } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [step, setStep] = useState<IStep>({
    description: '',
    duration: 0,
    id: 0,
    moodle_number: 0,
    moodle_url: '',
    name: '',
    practice: 0,
    self_learning: 0,
    step_code: '',
    task_id: 0,
  })
  const [task, setTask] = useState<ITask>({
    id: 0,
    program_id: 0,
    name: '',
    content_application: { applicant_user: { id: 0 } },
  })
  const [tasks, setTasks] = useState<ITask[]>([])
  const [trainerId, setTrainerId] = useState<number>(0)
  const [program, setProgram] = useState<IProgram>({ id: 0, name: '' })
  const [submissions, setSubmissions] = useState<ISubmission[]>([])
  const getData = async () => {
    try {
      setIsLoading(true)
      const stepResponse = await axios.get(`${API_URL}/steps/${stepId}`)
      const submResponse = await axios.get(`${API_URL}/step-content-submissions?step_id=${stepId}`)
      console.log(stepResponse.data)
      const taskResponse = await axios.get(`${API_URL}/tasks/${stepResponse.data.data.task_id}`)
      const programResponse = await axios.get(
        `${API_URL}/programs/${taskResponse.data.data.program_id}`
      )
      const tasksResponse = await axios.get(
        `${API_URL}/programs/instructional-design/${programId}/tasks`
      )
      setStep(stepResponse.data.data)
      setSubmissions(submResponse.data.data)
      setTask(taskResponse.data.data)
      setProgram(programResponse.data.data)
      setTasks(tasksResponse.data.data.tasks)
    } catch (err) {
      showErrorToaster('Sorry, something went wrong')
      setSubmissions([])
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    const curTask = tasks.find((newTask) => {
      return task.id === newTask.id
    })
    console.log(curTask?.content_application?.applicant_user.id)
    if (curTask) {
      setTrainerId(curTask?.content_application?.applicant_user.id)
    }
  }, [tasks])
  useEffect(() => {
    console.log(submissions[submissions.length[submissions.length - 1]])
  }, [submissions])
  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className='w-100 row g-5 g-xl-8 row mb-10 mt-5'>
            <div className='col-xl-6 d-flex flex-column'>
              <h1>{step?.name}</h1>
              <span className='text-secondary'>{task?.name}</span>
              <span className='text-secondary'>{program?.name}</span>
            </div>
            <div className='col-xl-6 d-flex flex-row align-items-center justify-content-sm-start justify-content-xl-end p-0'>
              <Link
                className='btn btn-primary d-flex justify-content-end'
                to={`/chat/${trainerId}`}
              >
                Contact Trainer
              </Link>
            </div>
          </div>
          <div className='separator my-10'></div>

          <div className='row g-5 g-xl-8 mb-15 w-100'>
            <div className='col-xl-6 d-flex flex-column '>
              <h1>Step Description</h1>
              <span>{step?.description}</span>
            </div>
            <div className='col-xl-6 d-flex justify-content-sm-start justify-content-xl-end'>
              <div className='card' style={{ width: '30%', maxHeight: 200 }}>
                <div className='px-10 card-body d-flex align-self-center justify-content-center'>
                  <img
                    className='d-flex align-self-center'
                    alt='Pic'
                    src={toAbsoluteUrl('/media/svg/mos.svg')}
                  />
                </div>
                <div className='card-footer p-0 m-0'>
                  <a
                    className={clsx(`${step?.moodle_url ? '' : 'disabled'} btn btn-primary h-100 w-100`)}
                  >
                    {' '}
                    Review Moodle Content
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='row g-5 g-xl-8 mb-7'>
            <div className='col-xl-6'>
              {submissions.length > 0 ? (
                <div>
                  {submissions[submissions.length - 1]?.approved === null && (
                    <ReviewSubmission submission={submissions[submissions.length - 1]} />
                  )}
                  {submissions[submissions.length - 1]?.approved === true && (
                    <div>
                      <ApprovedAlert />
                    </div>
                  )}
                  {submissions[submissions.length - 1]?.approved === false && (
                    <div>
                      <RejectedAlert />
                    </div>
                  )}
                </div>
              ) : (
                <WaitingAlert />
              )}
            </div>
            <div className='col-xl-6'>
              <StepActivity activities={submissions[submissions.length - 1]?.activities} />
            </div>
            {/* {(step?.phase === 'pending_submission') &&
                            <div className='col-xl-6'>
                                <div className=' cursor-default bg-light btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start'
                                >
                                    <div className='d-flex flex-row gap-5 '>
                                        <img className='' alt='oval' src={toAbsoluteUrl('/media/svg/Oval.svg')} />
                                        <div>
                                            <p classN)ame='text-align-left mb-2 p-0 fw-bold' style={{ textAlign: 'left' }}>Waiting for mentee submission!</p>
                                            <p className='text-secondary p-0 m-0' style={{ textAlign: 'left' }}>You will be able to see a submission screen, if the mentee submit this task.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>} */}
            {/* {(step?.phase === 'pending_submission_reply') &&
                            <div className='col-xl-6 card mt-10'>
                                <ApproveReview mentorship={mentorship} step={step} setStep={setStep} setIsTaskSubmitted={setIsToastSuccess} setIsToastError={setIsToastFalse} />
                            </div>
                        }
                        {(step?.phase === 'pending_review_both' || step?.phase === 'pending_review_mentor') &&
                            <div className='col-xl-6 card mt-10'>
                                <SubmitReview user={'Mentor'} setIsToastSuccess={setIsToastSuccess} setIsToastError={setIsToastFalse} />
                            </div >
                        }
                        <div className='col-xl-6'>
                            <StepActivity activities={step?.activities}/>
                        </div> */}
          </div>

          {/* <ProgramTasks mentorship={mentorship} /> */}
        </div>
      )}
      <ToastContainer />
    </div>
  )
}
