import { KTIcon, KTSVG } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

export const TrainingDescriptionModal = ({ trainingId, trainingData }) => {
  const [training, setTraining] = useState<any>();
  useEffect(() => {
    const getTraining = async () => {
      const response = axios.get(`${API_URL}/trainings/${trainingId.training}/show`).then((res) => {
        setTraining(res.data.data)
      }).catch(() => { })
    }
    console.log(trainingId)
    getTraining()
  }, [trainingId])
  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_training_description'>
      <div className='modal-dialog '>
        <div className='modal-content p-5'>
          <div className='modal-header'>
            <div>
              <h5 className='modal-title'>{trainingData?.name}</h5>
              <p className='text-secondary '>{trainingData?.program?.name}</p>
            </div>
          </div>
          <div className='modal-body'>
            <h5 className='modal-title'>Training Description</h5>
            <p>
              {trainingData?.training_description}
            </p>
            <div className='d-flex gap-3'>
              <p className='badge p-3' style={{ backgroundColor: "#EEF1F5", color: "#5E6278" }}>{trainingData?.duration} minutes</p>
              <p className='badge' style={{ backgroundColor: "#EEF1F5", color: "#5E6278" }}>Level &nbsp;
                {trainingData?.program?.level}
              </p>
              <p className='badge' style={{ backgroundColor: "#EEF1F5", color: "#5E6278" }}>
                {trainingData?.quota} pax</p>
            </div>
          </div>
          <div className='modal-footer'>
            <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
