import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import { Field, FieldArray, Form, Formik, FormikErrors, FormikHelpers } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IProgram, IProgramSubmit } from './AddNewProgram.page'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import * as Yup from 'yup'
import { ToastContainer } from 'react-toastify'
import { count } from 'console'

const ProgramSchema = Yup.object().shape({
  name: Yup.string().test('len', 'Name should include more than 5 characters', (val) => val !== undefined && val.toString().length >= 5
  ).required(),
  eqf_level: Yup.number().min(1, 'EQF level must be greater than zero').required(),
  ects: Yup.number().min(1, 'ECTS must be greater than zero').required(),
  tasks: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Task name is required'),
        training_description: Yup.string().required('Training description is required'),
      })
    )
    .required(),
  steps: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Step name is required'),
        practice: Yup.number().min(1, "Add some practice hours, please").required("Practice hours are required"),
        moodle_hour: Yup.number().min(1, "Add some moodle hours").required("Moodle hours are required"),
        training: Yup.number().min(1, 'Add some training hours, please').required("Training hours are required"),
        self_learning: Yup.number().min(1, 'Task name is required').required("Self-learning hours are required"),
        task_id: Yup.number().min(0, 'Select a task, please').required('Select a task'),
      })
    )
    .required(),
})

export const EditProgram = () => {
  const initialValue: IProgram = {
    name: '',
    eqf_level: 0,
    ects: 0,
    tasks: [{ name: '', training_description: '' }],
    steps: [{ moodle_hour: 1, name: '', practice: 1, self_learning: 1, task_id: -1, training: 1 }],
  }
  const { programId } = useParams()
  const [programDetail, setProgramDetail] = useState<any>([])
  const [tasks, setTasks] = useState<any>([])
  const [steps, setSteps] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const formikRef = useRef<any>();
  const [initialValueDraft, setInitialValueDraft] = useState<IProgram>(initialValue);

  const getProgram = async () => {
    const response = await axios.get(`${API_URL}/programs/${programId}`)
    setProgramDetail(response.data?.data)
    getTasks(programId)
  }

  const getTasks = async (program_id: any) => {
    const response = await axios.get(`${API_URL}/tasks?program_id=${program_id}`)
    setTasks(response.data?.data?.map((task: any) => ({ ...task, isNew: false, isChanged: false })))
    for (let i = 0; i < response.data?.data.length; i++) {
      const steps = await getSteps(response.data?.data[i].id)
      // setSteps([...steps])
      setSteps((prev) => [...prev, ...steps.map((step: any) => ({ ...step, isNew: false, isChanged: false }))])
    }
  }


  const getSteps = async (task_id: any) => {
    const response = await axios.get(`${API_URL}/steps?task_id=${task_id}`)
    return response.data?.data
  }

  const submitStep = async (values: IProgram, { resetForm }: FormikHelpers<IProgram>) => {
    console.log(values)
    setIsLoading(true)
    const programSubmitData: IProgramSubmit = {
      name: values.name,
      ects: values.ects,
      eqf_level: values.eqf_level,
      tasks: values.tasks.map((task, index) => ({
        name: task.name,
        training_description: task.training_description,
        steps: values.steps.filter((step) => step.task_id == index),
      })),
    }
    await axios
      .post(`${API_URL}/programs`, programSubmitData)
      .then(() => {
        showSuccessToaster('Program was edited succesfully!')
        resetForm();
      })
      .catch(() => {
        showErrorToaster(
          'Something went wrong! Please check entered information and try again please!'
        )
      })
    setIsLoading(false);

    console.log(programSubmitData)
  }

  const taskUpdate = async () => {
    Promise.all(
      tasks.map(async (task: any) => {
        if (task.isNew === false && task.isChanged === true) {
          updateTask(task)
        }
      })
    )
  }

  const taskCreate = async () => {
    const createTaskPromises = tasks.map(async (task: any) => {
      const oldTaskId = task.id;
      if (task.isNew) {
        const newTaskId = await createTask(task);

        // Update the task_id in steps
        updateStepsTaskId(oldTaskId, newTaskId);
      }
    });

    await Promise.all(createTaskPromises);
  };


  const stepUpdate = async () => {
    Promise.all(
      steps.map(async (step: any) => {
        if (step.isNew === false && step.isChanged === true) {
          updateStep(step)
        }
      })
    )
  }

  const stepCreate = async () => {
    Promise.all(
      steps.map(async (step: any) => {
        if (step.isNew === true) {
          createStep(step)
        }
      })
    )
  }

  const startUpdate = async () => {
    try {
      await taskCreate()
      await taskUpdate()
      await stepUpdate()
      await stepCreate()
      showSuccessToaster('Program was updated succesfully!')
      // after 4 seconds, reload the page
      setTimeout(() => {
        window.location.reload()
      }, 4000)
    } catch (error) {
      console.error('Error updating program:', error);
      showErrorToaster('Error updating program. Please try again.');
    } finally {

    }
  }

  // HELPER FUNCTIONS
  const updateStepsTaskId = (oldTaskId: number, newTaskId: number) => {
    // const updatedSteps = steps.map((step: any) =>
    //   step.task_id === oldTaskId ? { ...step, task_id: newTaskId } : step
    // );
    // setSteps(updatedSteps);
    console.log(oldTaskId, newTaskId, 'oldTaskId, newTaskId')
    const oldSteps = steps.filter((step: any) => step.task_id === oldTaskId);
    oldSteps.forEach((step: any) => {
      step.task_id = newTaskId;
    });
  };
  // REQUESTS
  const updateTask = async (task: any) => {
    await axios.post(`${API_URL}/tasks/${task.id}`, {
      name: task.name,
      training_description: task.training_description,
    })
  }
  const createTask = async (task: any) => {
    try {
      const response = await axios.post(`${API_URL}/tasks`, {
        program_id: programDetail.id,
        name: task.name,
        training_description: task.training_description,
      });

      const newTaskId = response.data.data.id;

      // Update the task's ID in the state
      const updatedTasks = tasks.map((t: any) =>
        t.id === task.id ? { ...t, id: newTaskId, isNew: false } : t
      );
      setTasks(updatedTasks);

      // Return the new task ID
      return newTaskId;
    } catch (error) {
      console.error('Error creating task:', error);
      showErrorToaster('Error creating task. Please try again.');
      throw error;
    }
  };
  const updateStep = async (step: any) => {
    await axios.post(`${API_URL}/steps/${step.id}`, {
      name: step.name,
      practice: step.practice,
      moodle_hour: step.moodle_hour,
      training: step.training,
      self_learning: step.self_learning,
      task_id: step.task_id
    })
  }

  const createStep = async (step: any) => {
    try {
      const response = await axios.post(`${API_URL}/steps`, {
        name: step.name,
        practice: step.practice,
        moodle_hour: step.moodle_hour,
        training: step.training,
        self_learning: step.self_learning,
        moodle_url: 'https://www.moodle.com',
        task_id: step.task_id,
      });

      const newStepId = response.data.data.id;

      // Update the step's ID in the state
      const updatedSteps = steps.map((s: any) =>
        s.id === step.id ? { ...s, id: newStepId, isNew: false } : s
      );
      setSteps(updatedSteps);
    } catch (error) {
      console.error('Error creating step:', error);
      showErrorToaster('Error creating step. Please try again.');
    }
  }


  useEffect(() => {
    getProgram()
  }, [])


  return (
    <div className='card card-custom mt-10'>
      <div className='card-header'>
        <h3 className='card-title w-100 mt-5'>Edit Program</h3>
        <p className='text-secondary fw-light'>{programDetail.name}</p>
      </div>
      <div className='card-body row'>
        {/* <b>
          Task Değişenler({
            tasks.filter((task: any) => task.isChanged === true && task.isNew === false)?.length
          })
        </b>
        <br />
        {
          // isNew === true olan taskları getir
          JSON.stringify(tasks.filter((task: any) => task.isChanged === true && task.isNew === false))
        }
        <br />
        <b>
          Task Yeniler({
            tasks.filter((task: any) => task.isChanged === true && task.isNew === true)?.length
          })
        </b>
        <br />
        ||||||||
        <b>
          Step Değişenler({
            steps.filter((step: any) => step.isChanged === true && step.isNew === false)?.length
          })
        </b>
        <br />
        {

          JSON.stringify(steps.filter((step: any) => step.isChanged === true && step.isNew === false))
        }
        <br />
        <b>
          Step Yeniler({
            steps.filter((step: any) => step.isChanged === true && step.isNew === true)?.length
          })
        </b>
        <br />
        {
          // isNew === true olan taskları getir
          JSON.stringify(steps.filter((step: any) => step.isChanged === true && step.isNew === true))
        }
        <br />
        <br />
        <br />
        {
          // isNew === true olan taskları getir
          JSON.stringify(tasks.filter((task: any) => task.isNew === true))
        }
        <br />
        <br />
        <br />
        {JSON.stringify(programDetail)}
        <br />
        <br />
        <br />

        {JSON.stringify(tasks)}
        <br />
        <br />
        <br />
        {JSON.stringify(steps)} */}
        <ProgramForm
          name={programDetail.name}
          eqf_level={programDetail.eqf_level}
          ects={programDetail.ects}
        />
        <br />

        {tasks.map((task: any, index: number) => (
          <TaskForm
            key={index}
            name={task.name}
            training_description={task.training_description}
            isNew={task.isNew ? true : false}
            steps={steps}
            deleteTask={() => {
              setTasks(tasks.filter((_, i) => i !== index))
            }}
            nameOnChange={(e) => {
              const newTasks = [...tasks]
              newTasks[index].name = e.target.value
              // when happens change, set isChanged to true
              newTasks[index].isChanged = true
              setTasks(newTasks)
            }}
            trainingDescriptionOnChange={(e) => {
              const newTasks = [...tasks]
              newTasks[index].training_description = e.target.value
              // when happens change, set isChanged to true
              newTasks[index].isChanged = true
              setTasks(newTasks)
            }}

          />
        ))}
        {/* New Task Button*/}
        <div className='mb-5'>
          <span
            className='btn btn-primary btn-sm'
            onClick={() => {
              setTasks([...tasks, {
                id: 9999999999 + (Math.random() * 1000).toFixed(0),
                name: '', training_description: '', isNew: true
              }])
            }}
          >
            Add New Task
          </span>
        </div>
        {steps.map((step: any, index: number) => (
          <StepForm
            key={index}
            name={step.name}
            practice={step.practice}
            moodle_hour={step.moodle_hour}
            training={step.training}
            self_learning={step.self_learning}
            task_id={step.task_id}
            isNew={step.isNew ? true : false}
            nameOnChange={(e) => {
              const newSteps = [...steps]
              newSteps[index].name = e.target.value
              newSteps[index].isChanged = true
              setSteps(newSteps)
            }}
            practiceOnChange={(e) => {
              const newSteps = [...steps]
              newSteps[index].practice = e.target.value
              newSteps[index].isChanged = true
              setSteps(newSteps)
            }}
            moodleOnChange={(e) => {
              const newSteps = [...steps]
              newSteps[index].moodle_hour = e.target.value
              newSteps[index].isChanged = true
              setSteps(newSteps)
            }}
            trainingOnChange={(e) => {
              const newSteps = [...steps]
              newSteps[index].training = e.target.value
              newSteps[index].isChanged = true
              setSteps(newSteps)
            }}
            selfLearningOnChange={(e) => {
              const newSteps = [...steps]
              newSteps[index].self_learning = e.target.value
              newSteps[index].isChanged = true
              setSteps(newSteps)
            }}
            taskSelectOnChange={(e) => {
              const newSteps = [...steps]
              newSteps[index].task_id = e.target.value
              newSteps[index].isChanged = true
              setSteps(newSteps)
            }
            }
            onDelete={() => {
              setSteps(steps.filter((_, i) => i !== index))
            }}
            tasks={tasks}

          />
        ))}
        <div className='mb-5'>
          <span
            className='btn btn-primary btn-sm'
            onClick={() => {
              setSteps([
                ...steps,
                {
                  name: '',
                  practice: 0,
                  moodle_hour: 0,
                  training: 0,
                  self_learning: 0,
                  task_id: null,
                  isNew: true,
                },
              ])
            }}
          >
            Add New Step
          </span>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-end gap-5'>
        <a className='btn btn-primary btn-sm px-10' onClick={() => {
          startUpdate()
        }}>Save</a>
      </div>
      <ToastContainer />
    </div>
  )
}

const ProgramForm = ({
  name,
  eqf_level,
  ects,
}: {
  name: string
  eqf_level: number
  ects: number

}) => {
  return (
    <>
      <div className='mb-5 row'>
        <div className='col-xl-12'>
          <label className='form-label fs-7'>Program Name</label>
          <input
            placeholder='Program Name'
            type='text'
            className='form-control form-control-solid'
            name='name'
            value={name}
          />
        </div>
      </div>

      <div className='row mb-7'>
        <div className='col-xl-6'>
          <label className='form-label fs-7'>EQF Level</label>
          <input
            placeholder='EQF Level'
            type='number'
            className='form-control form-control-solid'
            name='eqf_level'
            min='1'
            value={eqf_level}
          />
        </div>
        <div className='col-xl-6'>
          <label className='form-label fs-7'>ECTS</label>
          <input
            placeholder='ECTS'
            type='number'
            className='form-control form-control-solid'
            name='ects'
            min='1'
            value={ects}
          />
        </div>
      </div>
    </>
  )
}

const TaskForm = ({
  name,
  training_description,
  isNew,
  deleteTask,
  nameOnChange,
  trainingDescriptionOnChange,
  steps
}: {
  name: string
  training_description: string
  isNew?: boolean
  deleteTask?: () => void
  nameOnChange?: (e: any) => void
  trainingDescriptionOnChange?: (e: any) => void
  steps?: any
}
) => {
  return (
    <div className='mb-5 row'>
      <div className='col-xl-5'>
        <label className='form-label fs-7'>Task Name</label>
        <input
          placeholder={`Task Name`}
          type='text'
          className='form-control form-control-solid'
          name={`tasks.${0}.name`}
          value={name}
          onChange={nameOnChange}
        />
      </div>
      <div className='col-xl-5'>
        <label className='form-label fs-7'>Training Description</label>
        <input
          placeholder={`Training Description`}
          type='text'
          className='form-control form-control-solid'
          name={`tasks.${0}.training_description`}
          value={training_description}
          onChange={trainingDescriptionOnChange}
        />
      </div>
      {
        isNew && (
          <div
            className='col-xl-1 btn btn-sm btn-light px-1 py-1 mx-3 text-center d-flex align-items-center justify-content-center'
            style={{ alignSelf: 'end', height: '40px', width: '50px' }}
            onClick={deleteTask}
          >

            <i className='fa fa-times'></i>
            {
              // if any step is related to this task, show warning
            }
          </div>
        )
      }

    </div>
  )
}

const StepForm = ({
  name,
  practice,
  moodle_hour,
  training,
  self_learning,
  task_id,
  isNew,
  nameOnChange,
  practiceOnChange,
  moodleOnChange,
  trainingOnChange,
  selfLearningOnChange,
  taskSelectOnChange,
  tasks,
  onDelete,
}: {
  name: string
  practice: number
  moodle_hour: number
  training: number
  self_learning: number
  task_id: number
  isNew?: boolean
  nameOnChange?: (e: any) => void
  practiceOnChange?: (e: any) => void
  moodleOnChange?: (e: any) => void
  trainingOnChange?: (e: any) => void
  selfLearningOnChange?: (e: any) => void
  taskSelectOnChange?: (e: any) => void
  tasks?: any
  onDelete?: () => void
}) => {
  return (
    <div className='row mb-10'>
      <div className='col-xl-2'>
        <label className='form-label fs-7'>Step Name</label>
        <input
          className='form-control form-control-solid'
          placeholder='Step Name'
          name={`steps.${0}.name`}
          value={name}
          onChange={nameOnChange}
        ></input>
      </div>
      <div className='col-xl-2'>
        <label className='form-label fs-7'>Practice Hour</label>
        <input
          className='form-control form-control-solid'
          placeholder='Practice Hour'
          min='1'
          type='number'
          name={`steps.${0}.practice`}
          value={practice}
          onChange={practiceOnChange}
        ></input>
      </div>
      <div className='col-xl-2'>
        <label className='form-label fs-7'>Moodle hour</label>
        <input
          className='form-control form-control-solid'
          placeholder='Moodle hour'
          min='1'
          type='number'
          name={`steps.${0}.moodle`}
          value={moodle_hour}
          onChange={moodleOnChange}

        ></input>
      </div>
      <div className='col-xl-2'>
        <label className='form-label fs-7'>Training hour</label>
        <input
          className='form-control form-control-solid'
          placeholder='Training hour'
          min='1'
          type='number'
          name={`steps.${0}.training`}
          value={training}
          onChange={trainingOnChange}
        ></input>
      </div>
      <div className='col-xl-2'>
        <label className='form-label fs-7'>Self-learning Hour</label>
        <input
          className='form-control form-control-solid'
          placeholder='Self-learning Hour'
          min='1'
          type='number'
          name={`steps.${0}.self_learning`}
          value={self_learning}
          onChange={selfLearningOnChange}
        ></input>
      </div>
      <div className='col-xl-2'>
        <label className='form-label fs-7'>Task</label>
        <select className='form-control form-control-solid' name={`steps.${0}.task_id`}
          value={task_id}
          onChange={taskSelectOnChange}
        >
          <option value={-1}>Select Task</option>
          {tasks.map((task: any) => (
            <option value={task.id}>{task.name}</option>
          ))}

        </select>
      </div>

      {
        isNew && (
          <div
            className='col-xl-1 btn btn-sm btn-light px-1 py-1 mx-3 text-center d-flex align-items-center justify-content-center'
            style={{ alignSelf: 'end', height: '40px', width: '50px' }}
            onClick={onDelete}
          >
            <i className='fa fa-times'></i>
          </div>
        )
      }

    </div>
  )
}


