import {useIntl} from 'react-intl'
import {PageTitle} from '_metronic/layout/core'
import {DashboardBackgroundTiles} from './components/DasboardBackgroundTiles'
import {useEffect, useState} from 'react'
import axios from 'axios'
import {API_URL} from 'app/consts/consts'
interface IDashboad {
  instructional_design: {
    programs: {
      total: number
      completed: number
    }
    tasks: {
      total: number
      completed: number
    }
    steps: {
      total: number
      completed: number
    }
  }
  users: {
    mentors: {
      total: number
      active: number
      dropped: number
    }
    mentees: {
      total: number
      active: number
      dropped: number
    }
    trainers: {
      total: number
      active: number
      dropped: number
    }
  }
  activity: {
    mentorship: {
      total: number
      active: number
    }
    training: {
      total: number
      active: number
    }
    teamwork: {
      total: number
      active: number
    }
  }
  workload_hours: {
    total:number,
    active: number
}
}
const DashboardPage = () => {
  const [dashboard, setDashboard] = useState<IDashboad | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getDashboard = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API_URL}/dashboard/admin`)
      setDashboard(response.data)
    } catch (err) {
      setDashboard(null)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getDashboard()
  }, [])
  return (
    <>
      <div className='row g-5 g-xl-12'>
        <div className='col-xl-12'>
          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-12'>
              {dashboard && (
                <div className='row gx-xl-12'>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.instructional_design.programs.total}
                      countNumber={dashboard?.instructional_design.programs.completed}
                      completedName={'Completed Programs'}
                      className='card-xl-stretch'
                      color={'#F1416C'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.instructional_design.tasks.total}
                      countNumber={dashboard?.instructional_design.tasks.completed}
                      completedName='Completed Tasks'
                      className='card-xl-stretch'
                      color={'#F1416C'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.instructional_design.steps.total}
                      countNumber={dashboard?.instructional_design.steps.completed}
                      completedName='Completed Steps'
                      className='card-xl-stretch'
                      color={'#F1416C'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.users.mentors.total}
                      countNumber={dashboard?.users.mentors.active}
                      completedName='Active Mentors'
                      className='card-xl-stretch'
                      color={'#FFA800'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.users.trainers.total}
                      countNumber={dashboard?.users.trainers.active}
                      completedName='Active Trainers'
                      className='card-xl-stretch'
                      color={'#FFA800'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.users.mentees.total}
                      countNumber={dashboard?.users.mentees.active}
                      completedName='Active Mentees'
                      className='card-xl-stretch'
                      color={'#FFA800'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.activity.mentorship.total}
                      countNumber={dashboard?.activity.mentorship.active}
                      completedName='Active Mentorship'
                      className='card-xl-stretch'
                      color={'#008D8B'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.activity.training.total}
                      countNumber={dashboard?.activity.training.active}
                      completedName='Active Training'
                      className='card-xl-stretch'
                      color={'#008D8B'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.activity.teamwork.total}
                      countNumber={dashboard?.activity.teamwork.active}
                      completedName='Active Teamwork'
                      className='card-xl-stretch'
                      color={'#008D8B'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.users.mentors.total}
                      countNumber={dashboard?.users.mentors.dropped}
                      completedName='Dropped Mentors'
                      className='card-xl-stretch'
                      color={'#3F4254'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.users.trainers.total}
                      countNumber={dashboard?.users.trainers.dropped}
                      completedName='Dropped Trainers'
                      className='card-xl-stretch'
                      color={'#3F4254'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.users.mentees.total}
                      countNumber={dashboard?.users.mentees.dropped}
                      completedName='Dropped Mentees'
                      className='card-xl-stretch'
                      color={'#3F4254'}
                    />
                  </div>
                  <div className='col-xl-4 gx-5'>
                    <DashboardBackgroundTiles
                      wholeAmount={dashboard?.workload_hours?.total}
                      countNumber={dashboard?.workload_hours?.active}
                      completedName='Active Workload Hours'
                      className='card-xl-stretch'
                      color={'#663259'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MenteeDashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardPage}
