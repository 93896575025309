import React, { FC, useEffect, useRef, useState } from 'react'
import { IChat } from './UserList.component'
import { MessageModel, toAbsoluteUrl } from '_metronic/helpers'
import { useAuth } from 'app/modules/auth'
import clsx from 'clsx'
import axios from 'axios'
import { API_URL } from 'app/consts/consts'
import _ from 'lodash'
import fileDownload from 'js-file-download'
import { convertTimestamp } from 'utils/stringDateFunctions'
import { CreateZoom } from './CreateZoom.component'

function getTimeAgo(timestamp: string): string {
  const now = new Date()
  const createdDate = new Date(timestamp)
  const timeDifference = now.getTime() - createdDate.getTime()
  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (minutes < 2) {
    return 'Just now'
  } else if (minutes < 60) {
    return `${minutes} minutes ago`
  } else if (hours < 24) {
    return `${hours} hours ago`
  } else {
    return `${days} days ago`
  }
}

type Props = {
  isDrawer?: boolean
  chat: IChat
  setIntervalId: (id: any) => void
}
export const getFileDownloadLinkChat = async (file_id: number | null) => {
  if (!file_id) {
    return
  }
  const response = await axios.get(`${API_URL}/uploads/${file_id}/get-download-url`, {
    responseType: 'arraybuffer',
  })
  fileDownload(response.data, 'upload.pdf')
}

export const viewFileDownloadLink = async (file_id: number | null) => {
  if (!file_id) {
    return;
  }
  const response = await axios.get(`${API_URL}/files/${file_id}/download`);
  if (!response?.data?.url) {
    return;
  }
  window.open(response?.data?.url, '_blank')
}
export const viewFileDownloadLinkChat = async (file_id: number | null) => {
  if (!file_id) {
    return;
  }
  const response = await axios.get(`${API_URL}/uploads/${file_id}/get-download-url`);
  if (!response?.data?.url) {
    return;
  }
  window.open(response?.data?.url, '_blank')
}
let bufferMessages: Array<MessageModel> = []
const ChatCard: FC<Props> = ({ isDrawer = false, chat, setIntervalId }) => {
  const { currentUser } = useAuth()
  const [isSending, setIsSending] = useState<boolean>()
  const [isLoadDisabled, setIsLoadDisabled] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any[]>(bufferMessages)
  const [page, setPage] = useState<number>(1)
  const [isNeedToLoad, setIsNeedToLoad] = useState<boolean>(true)
  const chatRef = useRef<HTMLDivElement>(null)
  const [loadingBtns, setLoadingBtns] = useState<{ [key: string]: boolean }>({})

  const getChatInfo = async () => {
    setIsLoading(true)
    if (chat?.participant?.id) {
      const response = await axios.get(
        `${API_URL}/conversations/${chat.participant.id}?page=${page}`
      )
      const responseData: Array<any> = response.data.data
      console.log(responseData.length)
      if (responseData.length < 10) {
        setIsLoadDisabled(true)
      }
      console.log(responseData)
      if (responseData.length > 0) {
        const convertedMessages: MessageModel[] = await responseData.reverse().map((item: any) => {
          return {
            id: item?.id,
            type: item?.sender?.id == currentUser?.id ? 'out' : 'in',
            body: item?.body,
            data: item?.data,
            created_at: item?.created_at,
            sender: item?.sender,
          }
        })
        setMessages((prevMessages) => {
          const newMessages = convertedMessages.filter(
            (newMessage) =>
              !prevMessages.some((existingMessage) => existingMessage.id === newMessage.id)
          )
          if (newMessages.length > 0 && !isLoading) {
            console.log('1', prevMessages)
            console.log('2', newMessages)
            return [...newMessages, ...prevMessages]
          } else {
            return prevMessages
          }
        })
      }
    }
    setIsLoading(false)
  }
  const [file, setFile] = useState<any>()

  const getNewMessages = async () => {
    if (chat?.participant?.id) {
      const response = await axios.get(`${API_URL}/conversations/${chat.participant.id}?page=1`)
      const responseData: Array<any> = response.data.data
      const convertedMessages: MessageModel[] = await responseData.reverse().map((item: any) => {
        return {
          id: item.id,
          type: item.sender.id == currentUser?.id ? 'out' : 'in',
          body: item.body,
          data: item?.data,
          created_at: item.created_at,
          sender: item.sender,
        }
      })
      console.log(convertedMessages)
      console.log(_.isEqual(messages, convertedMessages))

      setMessages((prevMessages) => {
        const newMessages = convertedMessages.filter(
          (newMessage) =>
            !prevMessages.some((existingMessage) => existingMessage.id === newMessage.id)
        )
        if (newMessages.length > 0) {
          console.log('1', prevMessages)
          console.log('2', newMessages)
          return [...prevMessages, ...newMessages]
        } else {
          return prevMessages
        }
      })
    }
  }
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState('')
  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      setFile(file)
      setFileName(file.name)
    }
  }

  const sendMessage = async () => {
    setIsSending(true)

    let uploadedFile
    if (file) {
      const formData = new FormData()
      formData.append('file', file)
      const response = await axios.post(
        `${API_URL}/users/${chat.participant.id}/send-file`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      setMessages([...messages, { ...response.data.message }])
    }
    if (message) {
      const newMessage = {
        body: message,
      }
      const response = await axios.post(
        `${API_URL}/conversations/${chat.participant.id}/send-message`,
        newMessage
      )
      chatRef.current?.scrollIntoView({ behavior: 'smooth' })
      console.log(bufferMessages, messages)

      setMessages([...messages, response.data.message])

      setMessage('')
      scrollToBottom()
    }
    const fileInputElement = fileInputRef.current
    if (fileInputElement) {
      fileInputElement.value = ''
    }
    setFileName('')
    setFile(null)
    setIsSending(false)
  }
  const scrollToBottom = () => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const handleLoad = () => {
    setPage((prevPage) => prevPage + 1)
  }
  useEffect(() => {
    setMessages([])
    const intervalId = setInterval(getNewMessages, 10000)
    setIntervalId(intervalId)
    console.log(chat)
    return () => {
      clearInterval(intervalId)
    }
  }, [chat])
  useEffect(() => {
    if (messages.length === 0) {
      getNewMessages()
    }
  }, [messages])
  useEffect(() => {
    getChatInfo()
  }, [page])

  return (
    <div className='col-xl-7'>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>
            <p className='fw-bolder'>
              {chat?.participant?.name ? chat?.participant?.name : 'Select a chat'}
            </p>
          </h3>
          {chat?.participant?.role ? (
            <div className='card-toolbar'>
              <span
                className={clsx(
                  `badge badge-${chat?.participant?.role === 'trainer' ? 'danger' : 'primary'
                  } px-5 py-3`
                )}
              >
                {chat?.participant?.role}
              </span>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className='card-body' id='kt_chat_messenger_body'>
          <div className='scroll-y me-n5 pe-5 mh-500px h-lg-auto'>
            <div className='d-flex justify-content-center'>
              {messages.length > 24 && (
                <p
                  className={clsx(
                    ` btn btn-sm btn-primary cursor-pointer ${isLoading && 'disabled'} ${isLoadDisabled && 'd-none'
                    } `
                  )}
                  onClick={handleLoad}
                >
                  {isLoading ? 'Loading...' : 'Load More'}
                </p>
              )}
            </div>
            {messages.map((message, index) => {
              const state = message.type === 'in' ? 'info' : 'primary'

              const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.type === 'in' ? 'start' : 'end'
                } mb-10`
              return (
                <div
                  key={message.id}
                  className={clsx(
                    `d-flex justify-content-${message.type === 'in' ? 'start' : 'end'} mb-10`
                  )}
                >
                  <div
                    className={clsx(
                      `d-flex flex-column align-items-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                  >
                    {message.type === 'in' ? (
                      <div className='d-flex align-items-center mb-2'>
                        <div className='w-20px h-20px rounded align-self-start'>
                          <div className='symbol symbol-20px me-5'>
                            <img
                              src={toAbsoluteUrl('/media/avatars/blank.png')} />
                          </div>
                        </div>
                        <div className='ms-3'>
                          <a
                            href='#'
                            className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                          >
                            {message?.sender?.name}
                          </a>
                          <span className='text-muted fs-7 mb-1'>
                            {getTimeAgo(message.created_at)}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center mb-2'>
                        <div className='ms-3 d-flex gap-2 align-items-center justify-content-center'>
                          <span className='text-muted fs-7 mt-1'>
                            {getTimeAgo(message.created_at)}
                          </span>
                          <a
                            href='#'
                            className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                          >
                            {message?.sender?.name}
                          </a>
                        </div>
                        <div
                          className='w-40px h-40px rounded align-self-start'
                          style={{ marginLeft: 5 }}
                        >
                          <div
                            style={{ borderRadius: 15 }}
                            className={`w-100 h-100 d-flex flex-center text-white fs-2`}
                          >
                            <div className='w-20px h-20px rounded align-self-start'>
                              <div className='symbol symbol-20px me-5'>
                                <img
                                  src={toAbsoluteUrl('/media/avatars/blank.png')} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className={clsx(
                        `p-5 rounded bg-light-${message.type === 'in' ? 'info' : 'primary'
                        } text-gray-900 fw-semibold mw-lg-400px text-start`
                      )}
                      data-kt-element='message-text'
                    >
                      {message?.data?.zoom_meeting_id ? (
                        <>
                          <a
                            className={clsx(
                              'cursor-pointer',
                              'text-dark fw-semibold mw-lg-400px',
                              `text-${message?.type === 'in' ? 'start' : 'end'}`
                            )}
                            href={message?.data?.join_url}
                            data-kt-element='message-text'
                          >
                            <div className='d-flex flex-row align-items-center justify-content-between gap-3'>
                              <img
                                src={toAbsoluteUrl('/media/logos/zoomIcon.png')}
                                height='50'
                                width='50'
                              />
                              <div>
                                <p
                                  className='text-left fw-bolder mt-2'
                                  style={{ textAlign: 'left', maxWidth: '150px' }}
                                >
                                  {message?.body}
                                </p>
                                <p>
                                  {convertTimestamp(message?.data?.start_date).date} /
                                  {convertTimestamp(message?.data?.start_date).time}
                                </p>
                              </div>
                            </div>
                          </a>
                        </>
                      ) : (
                        <> {message?.body}</>
                      )}
                      {(message?.file || message?.data?.file_upload_id) && (
                        <div className='d-flex flex-row gap-3 mx-3'>
                          <img
                            className='mt-1 align-self-start'
                            alt='Pic'
                            width='50'
                            height='50'
                            src={toAbsoluteUrl('/media/icons/duotune/files/pdf.png')}
                          />
                          <div>
                            <p className='m-0 fw-bold'>File</p>
                            <span
                              className='btn p-0 m-0 text-hover-primary fw-thin'
                              onClick={() => {
                                setLoadingBtns((prevLoadingBtns) => ({
                                  ...prevLoadingBtns,
                                  [message?.data?.file_upload_id]: true,
                                }))
                                viewFileDownloadLinkChat(message?.data?.file_upload_id).finally(
                                  () => {
                                    setLoadingBtns((prevLoadingBtns) => ({
                                      ...prevLoadingBtns,
                                      [message?.data?.file_upload_id]: false,
                                    }))
                                  }
                                )
                              }}
                            >
                              {loadingBtns[message?.data?.file_upload_id] ? 'Loading...' : 'View'}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}

            <div ref={chatRef} />
          </div>
        </div>
        {chat && (
          <div className='card-footer p-5'>
            <textarea
              className='form-control form-control-solid'
              rows={3}
              data-kt-element='input'
              placeholder='Type a message'
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>

            <div className='d-flex flex-stack w-100 justify-content-between mt-3'>
              <div>
                <input
                  ref={fileInputRef}
                  className='btn btn-sm btn-primary'
                  style={{ display: 'none' }}
                  type='file'
                  id='file'
                  accept='.pdf,.docx'
                  onChange={handleFileSelect}
                />
                <button
                  className='btn'
                  onClick={() => {
                    document?.getElementById('file')?.click()
                  }}
                >
                  <img src={toAbsoluteUrl('/media/Clip.png')} />
                  {fileName}
                </button>
                <button className='btn p-0' data-bs-toggle='modal' data-bs-target='#create-zoom'>
                  <img src={toAbsoluteUrl('/media/Close1.png')} />
                </button>
              </div>
              <button
                className='btn btn-primary'
                type='button'
                data-kt-element='send'
                onClick={() => {
                  sendMessage()
                }}
              >
                {isSending ? 'Sending...' : 'Send'}
              </button>
            </div>
          </div>
        )}
      </div>
      <CreateZoom
        otherUserId={chat?.participant?.id}
        sendMessage={sendMessage}
        setMessages={setMessages}
        messages={messages}
      />
    </div>
  )
}

export default ChatCard
