import { KTSVG } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import { IApplicant, IMentor } from 'app/pages-admin/matching/pages/MatchProgram.page'
import axios from 'axios'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { showSuccessToaster } from 'utils/toasters'
interface Props {
  mentor: IMentor
  mentee: IApplicant
  isLoading: boolean
  setRelation: (id: number, assign: string) => void
  setIsSent: (isSent: boolean) => void
  isSent: boolean
}
export const AssigmentModal = ({ mentor, mentee, setRelation, isLoading, setIsSent, isSent }: Props) => {
  const { matchingId } = useParams()
  const [programName, setProgramName] = useState<string>('')
  const [isProgramLoading, setIsProgramLoading] = useState<boolean>()
  const getProgram = async () => {
    try {
      setIsProgramLoading(true)
      const response = await axios.get(`${API_URL}/programs/${matchingId}`)
      setProgramName(response?.data?.data?.name)
    } catch (err) {
      setProgramName('')
    } finally {
      setIsProgramLoading(false)
    }
  }
  useEffect(() => {
    getProgram()
  }, [])
  return (
    <div className='modal fade' tabIndex={-1} id='kt_assigment'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>{programName}</h5>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          {isProgramLoading ? (
            <div className='v-100 position-relative' style={{ minHeight: '40vh' }}>
              <div
                className='page-loading position-absolute'
                style={{
                  top: '50%',
                  left: '50%',
                }}
              >
                <span
                  className='spinner-border text-primary'
                  data-kt-app-page-loading='on'
                  role='status'
                >
                  <span className='visually-hidden'>Loading...</span>
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className='modal-body'>
                <p>
                  Do you want to assign <span className='fw-bolder'>{mentee?.name}</span> to{' '}
                  <span className='fw-bolder'>{mentor?.name}</span> for
                  <span className='fw-bolder'> {programName}</span>
                  program?
                </p>
              </div>
              <div className='modal-footer'>
                <div className='w-100 d-flex justify-content-between'>
                  <button
                    type='button'
                    className={clsx(`btn btn-light ${isLoading ? `disabled` : ``}`)}
                    data-bs-dismiss='modal'
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    className={clsx(`btn btn-primary ${(isLoading || isSent) ? `disabled` : ``}`)}
                    data-bs-dismiss='modal'
                    onClick={async () => {
                      await setRelation(mentor?.id, 'assign');
                      setIsSent(true)
                    }}
                  >
                    {isLoading ? `Assigning...` : `Assign`}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
