import {KTIcon, toAbsoluteUrl} from '_metronic/helpers'
import {ITask, getStatusText} from 'app/pages-admin/program/pages/InstructionalDesignTasks.page'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
interface Props {
  index: string
  task: ITask
  isProgress: boolean
}
export const TaskCollapseBox: React.FC<Props> = ({index, task, isProgress}) => {
  const [isOpen, setIsOpen] = useState<Boolean>(isProgress)
  const toggleCard = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className='card shadow-sm mb-3'>
      <a
        className={`card-header collapsible cursor-pointer rotate align-items-center py-3 ${
          isOpen ? '' : 'collapsed'
        }`}
        href={`#${index}`}
        style={{minHeight: 90, flexWrap: 'nowrap'}}
        onClick={toggleCard}
        data-bs-toggle='collapse'
        role='button'
        aria-expanded='false'
      >
        <div className='d-flex flex-row align-items-center h-100'>
          <h3 className='card-title fw-bold lh-base '>{task.name}</h3>
          {isOpen ? (
            <img src={toAbsoluteUrl('/media/svg/TitleOpen.svg')} className='w-40px h-40px' alt='' />
          ) : (
            <img src={toAbsoluteUrl('/media/svg/Title.svg')} className='w-40px h-40px' alt='' />
          )}
        </div>
        <div className='card-toolbar rotate-180'>
          <span className='svg-icon svg-icon-1'></span>
        </div>
      </a>
      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        <div className='card-body py-0'>
          <div className={`card py-0`}>
            <div className='card-header border-0 p-0 py-0 d-flex flex-column'>
              <div className='d-flex flex-row gap-3 align-items-center'>
                <img
                  className='w-40px h-40px rounded align-self-start my-4'
                  alt='Logo'
                  src={toAbsoluteUrl('/media/avatars/blank.png')}
                />
                <h3 className='fs-6'>{task?.content_application?.applicant_user?.name}</h3>
              </div>
              <h3 className='card-title fw-bold text-dark p-0 fs-6'>Steps</h3>
            </div>
            <div className='card-body pt-2 px-0'>
              {task?.steps?.length === 0 ? (
                <div className='d-flex align-items-center mb-8'>
                  <p className='text-gray-800 p-0 m-0 fw-bold fs-6'>There is no tasks yet</p>
                </div>
              ) : (
                task?.steps?.map((step) => {
                  return (
                    <div className='d-flex align-items-center mb-8 px-0 row'>
                      <div className='col-5 flex-grow-1 d-flex align-items-center'>
                        <span
                          className='bullet bullet-vertical h-40px'
                          style={{
                            marginRight: 7,
                            color: '#E4E6EF',
                            backgroundColor: '#E4E6EF',
                          }}
                        ></span>
                        <p className='text-gray-800 p-0 m-0 fw-bold fs-7'>{step.name}</p>
                      </div>
                      <div className='col-4 d-flex justify-content-center'>
                        <span
                          className='badge badge-light-success fs-8 fw-bold text-center py-3 px-3'
                          style={{
                            backgroundColor: `${getStatusText(step.status).color}`,
                            color: `${getStatusText(step.status).textColor}`,
                          }}
                        >
                          {getStatusText(step.status).text}
                        </span>
                      </div>
                      <div className='col-2 mx-1'>
                        <Link className='btn btn-sm btn-light px-1 py-1' to={`${step.id}`}>
                          <KTIcon iconName='arrow-right' className='text-success fs-1 p-0' />
                        </Link>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
