import {toAbsoluteUrl} from '_metronic/helpers'
import React from 'react'

export const RejectedAlert = () => {
  return (
    <div
      style={{borderColor: '#F64E60'}}
      className='cursor-default bg-light-danger btn btn-outline btn-outline-dashed btn-outline-default p-2 d-flex flex-column align-items-start'
    >
      <div className='d-flex flex-row gap-5 '>
        <img alt='warning-banner' className='' src={toAbsoluteUrl('/media/svg/Warning.svg')} />
        <div>
          <p className='text-align-left mb-2 p-0 fw-bold' style={{textAlign: 'left'}}>
            Rejected
          </p>
          <p className='text-secondary p-0 m-0' style={{textAlign: 'left'}}>
            You have already rejected this task
          </p>
        </div>
      </div>
    </div>
  )
}
