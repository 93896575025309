import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from './_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from './_metronic/layout/core'
import { MasterInit } from './_metronic/layout/MasterInit'
import { AuthInit } from './app/modules/auth'
import { ThemeModeProvider } from './_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { ErrorBoundary } from 'react-error-boundary'
// import * as Sentry from "@sentry/react";


const App = () => {


  // Sentry.init({
  //   dsn: "https://315bc3596c76a20d2061e71289e7bbb1@o4507226791215104.ingest.de.sentry.io/4507226809958480",
  //   integrations: [
  //     Sentry.browserTracingIntegration(),
  //     Sentry.replayIntegration(),
  //   ],
  //   // Performance Monitoring
  //   tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  //   // Session Replay
  //   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // });
  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </ErrorBoundary>
  )
}

export { App }
