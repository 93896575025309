import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { API_URL } from "app/consts/consts";
import { title } from "process";


const PlanningCalendar = () => {
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState<any>([])
    const [additionalParams, setAdditionalParams] = useState<any>({})
    const [programs, setPrograms] = useState<any>([])
    const getCalendarEvents = () => {
        setLoading(true)
        const params = {
            start_date: moment().startOf('year').format('YYYY-MM-DD'),
            end_date: moment().endOf('year').format('YYYY-MM-DD'),
            ...additionalParams
        }
        axios.get(`${API_URL}/calendar/planning`, { params }).then(res => {
            setEvents(
                Object.keys(res.data).flatMap((key) => {
                    const event = res.data[key];
                    return [
                        {
                            title: event.trainers + ' Trainers',
                            color: '#F64E60',
                            start: key,
                            end: key,
                            allDay: true,
                        },
                        {
                            title: event.mentors + ' Mentors',
                            color: '#008D8B',
                            start: key,
                            end: key,
                            allDay: true,
                        },
                        {
                            title: event.mentees + ' Mentees',
                            color: '#3F4254',
                            start: key,
                            end: key,
                            allDay: true,
                        },
                    ];
                })
            );


            console.log(res.data)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })

    }

    const getPrograms = async () => {
        const programs = await axios.get(`${API_URL}/programs`)
        setPrograms(programs?.data?.data)
    }

    useEffect(() => {
        getPrograms()
        getCalendarEvents()
    }, [])


    return (
        <div>
            <h1
                className="mt-4 text-3xl font-bold text-gray-900"
            >Planning Calendar</h1>
            <div
                className="d-flex justify-between mt-4"
            >
                <div>
                    <select
                        className="form-select"
                        onChange={(e) => {
                            console.log(e.target.value)
                            setAdditionalParams({
                                program_id: e.target.value
                            })
                            console.log(additionalParams, 'additionalParams')
                            getCalendarEvents()
                        }}
                    >
                        <option
                        >Select Program</option>
                        {programs.map((program: any) => (
                            <option
                                value={program.id}
                            >{program.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div
                className="card mt-4"
            >
                <div
                    className="card-body"
                >
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                    />

                </div>
            </div>
        </div>
    );
}

export default PlanningCalendar;

