import { API_URL } from "app/consts/consts";
import axios from "axios";
import { useEffect, useState } from "react";

const ExtraSettings = () => {

    return (
        <>
            <h1 className="text-center">Extra Settings</h1>
            <MoodleSync />
        </>
    );
}

const MoodleSync = () => {
    const [programList, setProgramList] = useState<any>([]);
    const [selectedProgram, setSelectedProgram] = useState<any>(null);
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getPrograms = async () => {
        try {
            setIsLoading(true)
            const response = await axios.get(`${API_URL}/programs`)
            console.log(response.data)
            if (Array.isArray(response?.data?.data)) {
                setProgramList(response?.data?.data)
            } else {
                console.error('response.data.data is not an array')
                setProgramList([])
            }
        } catch (err) {
            console.error(err)
            setProgramList([])
        } finally {
            setIsLoading(false)
        }
    }

    //POST /programs/{program}/sync
    const syncProgram = async (programId: number) => {
        setIsError(false)
        setIsSuccess(false)
        try {
            setIsLoading(true)
            const response = await axios.post(`${API_URL}/programs/${programId}/sync`)
            console.log(response.data)
            setIsSuccess(true)
        } catch (err) {
            setIsError(true)
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }



    useEffect(() => {
        getPrograms()
    }
        , [])



    return (
        <div
            className="card card-custom card-stretch"
        >
            <div className="card-header border-0">
                <h3 className="card-title fw-bolder text-dark">
                    Moodle Syncronization
                </h3>
            </div>
            <div
                className="card-body pt-0"
            >
                <div
                    className="d-flex flex-column flex-grow-2"
                >
                    <div

                    >
                        <span className="text-center">Programs</span>
                        <select
                            className="form-select form-select-solid form-select-sm"
                            onChange={(e) => {
                                setSelectedProgram(e.target.value)
                            }
                            }
                            value={selectedProgram}
                        >
                            {programList.map((program: any) => (
                                <option key={program.id} value={program.id}>
                                    {program.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div
                        className="d-flex flex-column flex-grow-2 mt-5"
                    >

                        <button
                            type="button"
                            className="btn btn-sm btn-light-primary"
                            onClick={() => {
                                syncProgram(selectedProgram)
                            }
                            }
                        >
                            <i className="bi bi-arrow-repeat"></i>
                            {isLoading ? 'Syncing...' : 'Sync Program with Moodle'}
                        </button>
                    </div>
                </div>
                {
                    isError && <div className="alert alert-danger mt-5">Error syncing program</div>
                }
                {
                    isSuccess && <div className="alert alert-success mt-5">Program synced successfully</div>
                }
                
            </div>
        </div>
    );
}

export default ExtraSettings;