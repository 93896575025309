import React, {useEffect, useRef, useState} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTIcon, WithChildren} from '../../../helpers'
import {Link} from 'react-router-dom'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  menuTrigger?: 'click' | `{default:'click', lg: 'hover'}`
  menuPlacement?: 'right-start' | 'bottom-start' | 'left-start'
  hasArrow?: boolean
  hasBullet?: boolean
  isMega?: boolean
}

const MenuInnerWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  menuTrigger,
  menuPlacement,
  hasArrow = false,
  hasBullet = false,
  isMega = false,
}) => {
  const menuItemRef = useRef<HTMLDivElement>(null)
  const {pathname} = useLocation()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [display, setDisplay] = useState<string>('none')
  const getDisplay = () => {
    return checkIsActive(pathname, to) ? 'flex' : 'none'
  }
  useEffect(() => {
    setDisplay(getDisplay())
  }, [pathname])
  useEffect(() => {
    if (menuItemRef.current && menuTrigger && menuPlacement) {
      menuItemRef.current.setAttribute('data-kt-menu-trigger', menuTrigger)
      menuItemRef.current.setAttribute('data-kt-menu-placement', menuPlacement)
    }
  }, [menuTrigger, menuPlacement])

  return (
    <div
      ref={menuItemRef}
      className='menu-item menu-lg-down-accordion mh-60px'
      onClick={() => {
        console.log('clickes')
        setIsOpen(true)
      }}
    >
      <Link
        to={to}
        style={{borderRadius: '0.475rem 0.475rem 0 0'}}
        className={clsx('menu-link py-3', {
          active: checkIsActive(pathname, to),
        })}
      >
        <span className='menu-title'>{title}</span>
      </Link>

      <div
        className={clsx('position-absolute sub-wrapper')}
        style={{top: 65, left: 0, height:50, display: display, backgroundColor: 'white', width: '100%', paddingLeft: '17%', gap: 10, paddingTop: 7, paddingBottom: 7}}
        data-kt-menu-dismiss='true'
      >
        {children}
      </div>
    </div>
  )
}

export {MenuInnerWithSub}
