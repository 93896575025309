import { MasterLayout } from '_metronic/layout/MasterLayout';
import AccessWrapper from 'app/pages-admin/access/AccessWrapper.routes';
import { CalendarWrapper } from 'app/pages-admin/calendar/ProgramWrapper';
import ChatWrapper from 'app/pages-admin/chat/ChatWrapper';
import { DashboardPage } from 'app/pages-admin/dashboard/DashboardWrapper';
import ExtraSettings from 'app/pages-admin/extra-settings/ExtraSettings';
import { MatchingWrapper } from 'app/pages-admin/matching/MatchingWrapper';
import { ProgramWrapper } from 'app/pages-admin/program/ProgramWrapper';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
const AdminRoutes = () => {
    return (
        <>
            <Routes>
                <Route element={<MasterLayout />}>
                    <Route
                        path='*'
                        element={<Navigate to='/dashboard' />}>
                    </Route>
                    <Route
                        path='/dashboard'
                        element={<DashboardPage />}
                    >
                    </Route>
                    <Route
                        path='/program/*'
                        element={<ProgramWrapper />}
                    >
                    </Route>
                    <Route
                        path='/matching/*'
                        element={<MatchingWrapper />}
                    >
                    </Route>
                    <Route
                        path='/access/*'
                        element={<AccessWrapper />}
                    >
                    </Route>
                    <Route
                        path='/calendar/*'
                        element={<CalendarWrapper />}
                    >
                    </Route>
                    <Route
                        path='/chat/*'
                        element={<ChatWrapper />}
                    >
                    </Route>
                    <Route
                        path='/extra-settings'
                        element={<ExtraSettings />}
                    >
                    </Route>
                </Route>
            </Routes>
        </>
    );
};

export default AdminRoutes;