import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccessMain from './pages/AccessMain.page';

const AccessWrapper = () => {
    return (
        <Routes>
            <Route
                path='/*'
                element={<AccessMain/>}>
            </Route>
        </Routes>
    );
};

export default AccessWrapper;