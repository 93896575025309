import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import React, {useEffect, useState} from 'react'
import {enGB} from 'date-fns/locale'
const DatePickerPeriods = ({
  startDate,
  setStartDate,
  setFieldValue,
}: {
  startDate: any
  setStartDate: any
  setFieldValue?: any
}) => {
  const timeRange = 40

  const formatDateRange = (date) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }
    const startTime = date.toLocaleString('en-US', options)
    const endTime = new Date(date.getTime() + timeRange * 60000).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })

    return `${startTime} - ${endTime}`
  }

  const isWeekday = (date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6
  }

  const filterTimes = (time) => {
    const selectedHour = time.getHours()
    return selectedHour >= 8 && selectedHour < 23
  }
  useEffect(()=>{
    if(setFieldValue) {
        setFieldValue('date',new Date(startDate)?.toISOString().slice(0, 19).replace('T', ' '))
    }
  },[])
  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => {
        if(setFieldValue) {
            setFieldValue('date',new Date(date)?.toISOString().slice(0, 19).replace('T', ' '))
        }
        setStartDate(date)
      }}
      showTimeSelect
      filterDate={isWeekday}
      filterTime={filterTimes}
      timeFormat='HH:mm'
      timeIntervals={timeRange}
      timeCaption='Time'
      dateFormat='MMMM d, yyyy h:mm aa'
      placeholderText='Select date and time'
      className='form-control w-100'
      locale={enGB}
    />
  )
}

export default DatePickerPeriods
