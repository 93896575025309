import { KTIcon, toAbsoluteUrl } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import { ProfileModal } from 'components/admin/ProfileModal.component'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AssigmentModal } from 'components/admin/AssigmentModal.component.page'
export interface IApplicant {
  id: number
  name: string
  status: string
}
export interface IMentor {
  id: number
  name: string
  active_mentee: 0
  assigned: boolean
  admin_can_retract_mentor: boolean
}
const MatchProgram = () => {
  const { matchingId } = useParams()
  const [applicants, setApplicants] = useState<IApplicant[]>([])
  const [applicantId, setApplicantId] = useState<number>(0)
  const [activeApplicant, setActiveApplicant] = useState<IApplicant>({ id: 0, name: '', status: '' })
  const [mentors, setMentors] = useState<IMentor[]>([])
  const [mentor, setMentor] = useState<IMentor>({
    id: 0,
    active_mentee: 0,
    admin_can_retract_mentor: false,
    assigned: false,
    name: '',
  })
  const [isApplicantsLoading, setIsApplicantsLoading] = useState<boolean>(false)
  const [isProgramsLoading, setIsProgramsLoading] = useState<boolean>(false)
  const [loadingButtons, setLoadingButtons] = useState<number[]>([])
  const [role, setRole] = useState<string>('')
  const [isSent, setIsSent] = useState<boolean>(false)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const getApplicants = async () => {
    setMentors([])
    try {
      setIsApplicantsLoading(true)
      const response = await axios.get(`${API_URL}/matching/${matchingId}/mentees?status=${status}`)
      setApplicants(response.data.data)
      // setActiveApplicant(response.data.data[0])
    } catch (err) {
      setApplicants([])
    } finally {
      setIsApplicantsLoading(false)
    }
  }
  const getMentors = async () => {
    try {
      setIsProgramsLoading(true)
      const response = await axios.get(
        `${API_URL}/matching/${matchingId}/mentees/${activeApplicant?.id}/available-mentors`
      )
      console.log(response)
      setMentors(response.data.data)
    } catch (err) {
      showErrorToaster('Sorry,something went wrong')
      setMentors([])
    } finally {
      setIsProgramsLoading(false)
    }
  }

  const setRelation = async (mentor_user_id: number, relation: string) => {
    try {
      setIsLoading(true)
      setLoadingButtons((prevLoadingButtons) => [...prevLoadingButtons, mentor_user_id])

      const response = await axios.post(
        `${API_URL}/matching/${matchingId}/mentees/${activeApplicant?.id}/set-relation`,
        { mentor_user_id, relation }
      )
      if (response.data.success === true) {
        // const updatedMentors = mentors.map((mentor) => {
        //   if (mentor.id === mentor_user_id) {
        //     mentor.assigned = !mentor.assigned
        //   }
        //   return mentor
        // })
        // setMentors(updatedMentors)
        getApplicants()
        showSuccessToaster('Assigned successfully')
      } else {
        console.error('Failed to set relation:', response.data.error)
      }
    } catch (error) {
      showErrorToaster('Sorry,something went wrong')
    } finally {
      setIsLoading(false)
      setLoadingButtons((prevLoadingButtons) =>
        prevLoadingButtons.filter((buttonId) => buttonId !== mentor_user_id)
      )
    }
  }
  const [status, setStatus] = useState<string>('active')

  useEffect(() => {
    if (activeApplicant?.id !== undefined && activeApplicant.id !== 0) {
      getMentors()
    }
  }, [activeApplicant])
  useEffect(() => {
    getApplicants()
  }, [])
  useEffect(() => {
    getApplicants()
  }, [status])

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  return (
    <div className='row'>
      <div className='col-xl-7'>
        <div className='card card-flush h-xl-100 cursor-pointer'>
          <div className='card-header'>
            <div className='card-title flex-column'>
              <h3 className='mt-4'>Applicants</h3>
              <p className='fs-9 text-secondary fw-light'>
                {applicants?.length}
                applicants listed</p>
            </div>
            <div className='card-toolbar'>
              <select
                className='form-select form-select-solid'
                aria-label='Select example'
                value={status}
                onChange={handleStatusChange}
              >
                <option value='active'>Active</option>
                <option value='passive'>Passive</option>
              </select>
            </div>
          </div>

          <div className='card-body py-3 scroll-y' style={{ maxHeight: 700 }}>
            {isApplicantsLoading ? (
              <div className='v-100 position-relative' style={{ minHeight: '500px' }}>
                <div
                  className='page-loading position-absolute'
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span
                    className='spinner-border text-primary'
                    data-kt-app-page-loading='on'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                </div>
              </div>
            ) : (
              <div className=''>
                <table className='table table-row-bordered table-row-gray-300 gy-7'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th className='fw-bolder text-secondary px-2'>Name</th>
                      <th className='fw-bolder text-secondary'>Status</th>
                      <th className='fw-bolder text-secondary'>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicants?.map((applicant) => {
                      return (
                        <tr
                          key={applicant?.id}
                          className=''
                          style={{
                            backgroundColor:
                              applicant.id === activeApplicant?.id ? '#E5F3F3' : 'inherit',
                          }}
                        >
                          <td className=''>
                            <div className='d-flex px-2'>
                              <div className='w-40px h-40px rounded' style={{ marginRight: 10 }}>
                                <div
                                  style={{ borderRadius: 15 }}
                                  className={`w-100 h-100 d-flex flex-center rounded bg-light-secondary text-white fs-2 fw-bolder`}
                                >
                                  <img
                                    className='w-40px h-40px rounded'
                                    alt='Logo'
                                    src={toAbsoluteUrl('/media/avatars/blank.png')}
                                  />
                                </div>
                              </div>
                              <p className='mt-2'>{applicant?.name}</p>
                            </div>
                          </td>
                          <td>
                            {applicant.status === 'match_required' && (
                              <span className='badge badge-danger px-5 py-2 mt-1'>
                                Match Required
                              </span>
                            )}
                            {applicant.status === 'assigned' && (
                              <span className='badge badge-primary px-5 py-2 mt-1'>Assigned</span>
                            )}
                            {applicant.status === 'completed' && (
                              <span className='badge badge-primary px-5 py-2 mt-1' style={{ backgroundColor: '#3F4254', color: "#FFF" }}>Completed</span>
                            )}
                            {applicant.status === 'dropped' && (
                              <span className='badge badge-primary px-5 py-2 mt-1' style={{ backgroundColor: '#FFF4DE', color: "#FFA800" }}>Dropped</span>
                            )}
                          </td>
                          <td className=''>
                            <div className='d-flex flex-row gap-3'>
                              <div
                                className='btn btn-sm btn-light d-flex justify-content-center align-items-center '
                                style={{ width: 20, height: 30 }}
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_1'
                                onClick={() => {
                                  setApplicantId(applicant.id)
                                  setRole('mentee')
                                }}
                              >
                                <img
                                  className=''
                                  src={toAbsoluteUrl('/media/svg/person.svg')}
                                ></img>
                              </div>
                              <div
                                className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                style={{ width: 20, height: 30 }}
                                onClick={() => {
                                  setActiveApplicant(applicant)
                                }}
                              >
                                <KTIcon
                                  iconName='arrow-right'
                                  className='ki-duotone ki-arrow-right text-primary fs-1'
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='col-xl-5'>
        <div className='card card-flush h-xl-100 cursor-pointer'>
          <div className='card-header'>
            <div className='card-title flex-column'>
              <h3 className='mt-4'>Mentors</h3>
              <p className='fs-9 text-secondary fw-light'>
                {mentors?.length}
                applicants listed</p>
            </div>
          </div>
          <div className='card-body py-3 scroll-y' style={{ maxHeight: 700 }}>
            {isProgramsLoading ? (
              <div className='v-100 position-relative' style={{ minHeight: '500px' }}>
                <div
                  className='page-loading position-absolute'
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span
                    className='spinner-border text-primary'
                    data-kt-app-page-loading='on'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                </div>
              </div>
            ) : (
              <div className='mh-500px'>
                <table className='table table-row-bordered table-row-gray-300 gy-7'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th className='fw-bolder text-secondary' style={{ width: '35%' }}>
                        Name
                      </th>
                      <th className='fw-bolder text-secondary'>TOTAL ACTIVE MENTEES</th>
                      <th className='fw-bolder text-secondary'>ACTION</th>
                    </tr>
                  </thead>
                  <tbody className='scroll-y mh-500px'>
                    {mentors.length > 0 &&
                      mentors?.map((mentor) => {
                        return (
                          <tr>
                            <td style={{ paddingLeft: '7px' }}>
                              <p>{mentor?.name}</p>
                            </td>
                            <td>
                              <p>{mentor?.active_mentee}</p>
                            </td>
                            <td>
                              <div className='d-flex flex-row gap-3 align-items-start justtify-content-start'>
                                <div
                                  className='btn btn-sm btn-light w-30px h-30px d-flex justify-content-center align-items-center'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_1'
                                  onClick={() => {
                                    setApplicantId(mentor.id)
                                    setRole('mentor')
                                  }}
                                >
                                  <img
                                    className=''
                                    src={toAbsoluteUrl('/media/svg/person.svg')}
                                  ></img>
                                </div>
                                {mentor?.assigned ? (
                                  <div
                                    className='btn btn-sm btn-light w-30px h-30px d-flex justify-content-center align-items-center'
                                    onClick={() => {
                                      setRelation(mentor?.id, 'retract')
                                    }}
                                  >
                                    {loadingButtons.includes(mentor.id) ? (
                                      <div className='page-loading'>
                                        <span
                                          className='spinner-border text-primary'
                                          data-kt-app-page-loading='on'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>Loading...</span>
                                        </span>
                                      </div>
                                    ) : (
                                      <img
                                        className=''
                                        src={toAbsoluteUrl('/media/svg/ShapeX.svg')}
                                      ></img>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className='btn btn-sm btn-light w-30px h-30px d-flex justify-content-center align-items-center'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_assigment'
                                    onClick={() => {
                                      setMentor(mentor)
                                      setIsSent(false)
                                      // setRelation(mentor?.id, 'assign')
                                    }}
                                  >
                                    {loadingButtons.includes(mentor.id) ? (
                                      <div className='page-loading'>
                                        <span
                                          className='spinner-border text-primary'
                                          data-kt-app-page-loading='on'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>Loading...</span>
                                        </span>
                                      </div>
                                    ) : (
                                      <img
                                        className=''
                                        src={toAbsoluteUrl('/media/svg/Shape.svg')}
                                      ></img>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      <AssigmentModal
        mentee={activeApplicant}
        mentor={mentor}
        setRelation={setRelation}
        isLoading={isLoading}
        setIsSent={setIsSent}
        isSent={isSent}
      />
      <ProfileModal id={applicantId} role={role} />
    </div>
  )
}

export default MatchProgram
