import React from 'react'
import AllProgramsTable from '../components/AllProgramsTable.component'

const AllPrograms = () => {

  return (
    <div className='mt-10'>
        <AllProgramsTable/>
    </div>
  )
}

export default AllPrograms