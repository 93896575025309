import { Navigate, Route, Routes } from 'react-router-dom'
import MainCalendar from './pages/MainCalendar'
import PlanningCalendar from './pages/PlanningCalendar'

export const CalendarWrapper = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='main' />} />
      <Route path='/main' element={<MainCalendar />}></Route>
      <Route path='/planning' element={<PlanningCalendar />} />
    </Routes>
  )
}
