import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import { ITask } from 'app/pages-admin/program/pages/InstructionalDesignTasks.page'
import axios from 'axios'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface Props {
  taskId: number
  userId: number
  task: ITask | undefined
  showSuccessToaster: (messagea: string) => void
  showErrorToaster: (messagea: string) => void
  getProgramInfo: () => void
}
export const InstructionalDesignApplyModal = ({
  taskId,
  task,
  userId,
  showSuccessToaster,
  showErrorToaster,
  getProgramInfo,
}: Props) => {
  const { programId } = useParams<{ programId: string }>() // Add type for useParams
  const [isSent, setIsSent] = useState<boolean>(false)
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isRejecting, setIsRejecting] = useState<boolean>(false)
  const approveOrReject = async (approve: boolean) => {
    try {
      if (!approve) {
        setIsRejecting(true)
      } else {
        setIsApproving(true)
      }
      const response = await axios.post(
        `${API_URL}/programs/instructional-design/${programId}/approve-reject`,
        {
          task_id: task?.id,
          user_id: task?.content_application?.applicant_user?.id,
          approved: approve,
        }
      )
      setIsSent(true)
      showSuccessToaster(`Successfully ${approve ? 'approved' : 'rejected'}!`)

      getProgramInfo()
    } catch (err) {
      showErrorToaster('Sorry, something went wrong')
    } finally {
      if (!approve) {
        setIsRejecting(false)
      } else {
        setIsApproving(false)
      }
    }
  }
  useEffect(() => {
    setIsSent(false)
  }, [taskId])
  return (
    <div className='modal fade' tabIndex={-1} id='kt_instuctional_design'>
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div>
              <h5 className='modal-title'>{task?.name && task?.name}</h5>
              <p className='color-secondary' style={{ color: '#B5B5C3' }}>
                {task?.program?.name}
              </p>
            </div>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div> 
          </div>
          <div className='modal-body'>
            <div className='d-flex flex-row gap-3 align-items-center'>
              <img
                className='w-40px h-40px rounded align-self-start my-4'
                alt='Logo'
                src={toAbsoluteUrl('/media/avatars/blank.png')}
              />
              <h3 className='fs-6'>{task?.content_application?.applicant_user?.name}</h3>
            </div>
            <h5 className='modal-title'>Motivation Letter</h5>
            <p className='color-secondary' style={{ color: '#B5B5C3' }}>
              {task?.content_application && task?.content_application?.motivation_letter}
            </p>
          </div>
          <div className='modal-footer'>
            <div className='w-100 d-flex justify-content-between'>
              <button
                type='button'
                className={clsx(
                  `btn btn-danger ${isSent && 'disabled'} ${isApproving || isRejecting ? 'disabled' : ''
                  }`
                )}
                data-bs-dismiss='modal'
                style={{ backgroundColor: '#F64E60', color: '#Fff' }}
                onClick={() => {
                  approveOrReject(false)
                }}
              >
                {isRejecting ? 'Rejecting...' : 'Reject'}
              </button>
              <button
                type='button'
                className={clsx(
                  `btn btn-primary ${isSent && 'disabled'} ${isApproving || isRejecting ? 'disabled' : ''
                  }`
                )}
                onClick={() => {
                  approveOrReject(true)
                }}
              >
                {isApproving ? 'Approving...' : 'Approve'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
