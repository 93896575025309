import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import AllPrograms from './pages/AllPrograms.page'
import InstructionalDesign from './pages/InstructionalDesign.page'
import { TrainingAuthorization } from './pages/TrainingAuthorization.page'
import AddNewProgram from './pages/AddNewProgram.page'
import InstructionalDesignTasks from './pages/InstructionalDesignTasks.page'
import TrainingApplicantsTable from './pages/TrainingApplicantsTable.page'
import { InstructionalDesignTaskApprovement } from './pages/InstructionalDesignTaskApprovement.page'
import { EditProgram } from './pages/EditProgram.page'

export const ProgramWrapper = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="all-programs" />} />
      <Route path='/all-programs' element={<AllPrograms />}></Route>
      <Route path='/all-programs/edit-program/:programId' element={<EditProgram />}></Route>
      <Route path='/all-programs/new-program' element={<AddNewProgram/>}></Route>
      <Route path='/instructional-design' element={<InstructionalDesign />}></Route>
      <Route path='/instructional-design/:programId/tasks' element={<InstructionalDesignTasks />}></Route>
      <Route path='/instructional-design/:programId/tasks/:stepId' element={<InstructionalDesignTaskApprovement />}></Route>
      <Route path='/training-authorization' element={<TrainingAuthorization/>}></Route>
      <Route path='/training-authorization/:trainingId' element={<TrainingApplicantsTable/>}></Route>
    </Routes>
  )
}
