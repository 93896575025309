import React, { useState } from 'react'
import { ISubmission } from '../pages/InstructionalDesignTaskApprovement.page'
import { toAbsoluteUrl } from '_metronic/helpers'
import { showErrorToaster, showSuccessToaster } from 'utils/toasters'
import axios from 'axios'
import { API_URL } from 'app/consts/consts'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
interface Props {
  submission: ISubmission
}
export const ReviewSubmission = ({ submission }: Props) => {
  const { stepId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSent, setIsSent] = useState<boolean>(false)

  const review = async (approved: boolean) => {
    try {
      setIsLoading(true)
      const response = await axios.post(`${API_URL}/step-content-submissions/${submission.id}/reply`, { approved, description: 'thanks trainer' })
      setIsSent(true)
      showSuccessToaster('Already approved')
      window.location.reload();
    } catch (err) {
      showErrorToaster('Sorry,something went wrong')
    } finally {
      setIsLoading(false)
      //refresh  
    }
  }
  return (
    <div className='card'>
      <div className='card-header m-0'>
        <div className='card-title'>
          <h3 className='card-label'>Submit Task Review</h3>
        </div>
      </div>

      <div className='card-body pt-3 pb-5'>
        {submission.content.map((content) => (
          <p className='text-secondary'>{content}</p>
        ))}
        {submission?.uploads?.map((upload) => (
          <div className='d-flex flex-row gap-3'>
            <img
              className='mt-1 align-self-start'
              alt='Pic'
              width='50'
              height='50'
              src={toAbsoluteUrl('/media/icons/duotune/files/pdf.png')}
            />
            <div>
              <p className='m-0 fw-bold'>Submission</p>
              <span
                className='btn p-0 m-0 text-hover-primary fw-thin'
                onClick={() => {
                  window.open(upload.download_url, '_blank')
                  //   setLoadingBtns((prevLoadingBtns) => ({
                  //     ...prevLoadingBtns,
                  //     [doc.id]: true,
                  //   }))
                  //   getFileDownloadLink(doc?.id).finally(() => {
                  //     setLoadingBtns((prevLoadingBtns) => ({
                  //       ...prevLoadingBtns,
                  //       [doc.id]: false,
                  //     }))
                  //   })
                }}
              >
                View
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className='card-footer'>
        <div className='w-100 d-flex gap-5'>
          <button type='submit' className={clsx(`btn btn-primary ${(isSent || isLoading) ? 'disabled' : ''}`)} onClick={() => { review(true) }}>
            Approve
          </button>
          <button className={clsx(`btn btn-danger ${(isSent || isLoading) ? 'disabled' : ''}`)} onClick={() => { review(false) }}>Reject</button>
        </div>
      </div>
    </div>
  )
}
