import { KTIcon, KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import { ProfileModal } from 'components/admin/ProfileModal.component'
import { TrainingDescriptionModal } from 'components/admin/TrainingDescriptionModal.component'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
interface IApplicant {
  user: { id: number; name: string; status: string }
  training: Array<{
    id: number
    task_id: number
    name: string | null
    active_trainers: number
    approved: boolean | null
  }>
}
const TrainingApplicantsTable = () => {
  const { trainingId } = useParams()
  const [status, setStatus] = useState<string>('active')
  const [loadingButtons, setLoadingButtons] = useState<number[]>([])
  const [isApplicantsLoading, setIsApplicantsLoading] = useState<boolean>(false)
  const [applicants, setApplicants] = useState<IApplicant[]>([])
  const [activeApplicant, setActiveApplicant] = useState<IApplicant>({
    user: { id: 0, name: '', status: '' },
    training: [],
  })
  const [isActionLoading, setActionLoading] = useState<boolean>()
  const [trainerId, setTrainerId] = useState<number>(0)
  const [independentTrainingId, setIndependentTrainingId] = useState<number>(0)
  const [selectedTraining, setSelectedTraining] = useState<any>({})
  const programAction = async (action: boolean, program_id: number) => {
    setActionLoading(true)
    setLoadingButtons((prevLoadingButtons) => [...prevLoadingButtons, program_id])
    const response = await axios.post(`${API_URL}/trainings/${program_id}`, {
      approved: action,
    })
    if (response.data.success === true) {
      const updatedPrograms = activeApplicant.training.map((program) => {
        if (program.id === program_id) {
          program.approved = !program.approved
        }
        return program
      })
      setActiveApplicant((prevApplicant) => {
        const updatedApplicant = { ...prevApplicant }
        updatedApplicant.training = updatedApplicant.training.map((program) => {
          if (program.id === program_id) {
            return {
              ...program,
              approved: action,
            }
          }
          return program
        })
        return updatedApplicant
      })
    } else {
      console.error('Failed to set relation:', response.data.error)
    }
    setLoadingButtons((prevLoadingButtons) =>
      prevLoadingButtons.filter((buttonId) => buttonId !== program_id)
    )
    getApplicants()
    setActionLoading(true)
  }
  const getApplicants = async () => {
    try {
      setIsApplicantsLoading(true)
      const response = await axios.get(
        `${API_URL}/trainings/${trainingId}/applications?status=${status}`
      )
      setApplicants(response.data.data)
      setActiveApplicant(response.data.data[0])
    } catch (err) {
      setApplicants([])
    } finally {
      setIsApplicantsLoading(false)
    }
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)
  }

  useEffect(() => {
    getApplicants()
  }, [])
  useEffect(() => {
    getApplicants()
  }, [status])
  return (
    <div className='row mt-10'>
      <div className='col-xl-6'>
        <div className='card card-flush h-xl-100 cursor-pointer'>
          <div className='card-header'>
            <div className='card-title flex-column'>
              <h3 className='mt-4'>Applicants</h3>
              <p className='fs-9 text-secondary fw-light'>{applicants?.length} applicants listed</p>
            </div>
            <div className='card-toolbar'>
              <select
                className='form-select form-select-solid'
                aria-label='Select example'
                value={status}
                onChange={handleStatusChange}
              >
                <option value='active'>Active</option>
                <option value='passive'>Passive</option>
              </select>
            </div>
          </div>
          <div className='card-body py-3'>
            {isApplicantsLoading ? (
              <div className='v-100 position-relative' style={{ minHeight: '500px' }}>
                <div
                  className='page-loading position-absolute'
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span
                    className='spinner-border text-primary'
                    data-kt-app-page-loading='on'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                </div>
              </div>
            ) : (
              <div className=''>
                <table className='table table-row-bordered table-row-gray-300'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th className='fw-bolder text-secondary'>Name</th>
                      <th className='fw-bolder text-secondary'>Status</th>
                      <th className='fw-bolder text-secondary'>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicants.length > 0 &&
                      applicants?.map((applicant) => {
                        return (
                          <tr
                            key={applicant?.user.id}
                            style={{
                              backgroundColor:
                                applicant.user.id === activeApplicant?.user?.id
                                  ? '#E5F3F3'
                                  : 'inherit',
                            }}
                          >
                            <td>
                              <div className='d-flex flex-row'>
                                <div className='w-40px h-40px rounded' style={{ marginRight: 10 }}>
                                  <div
                                    style={{ borderRadius: 15 }}
                                    className={`w-100 h-100 d-flex flex-center rounded bg-light-secondary text-white fs-2 fw-bolder mx-2 `}
                                  >
                                    <img
                                      className='w-40px h-40px rounded'
                                      alt='Logo'
                                      src={toAbsoluteUrl('/media/avatars/blank.png')}
                                    />
                                  </div>
                                </div>
                                <p className='fw-bolder mb-0 mt-2'>{applicant?.user.name}</p>
                              </div>
                            </td>
                            <td>
                              {applicant?.user?.status === 'Assigned' && (
                                <span className='badge badge-primary px-5 py-2 mt-1'>Assigned</span>
                              )}
                              {applicant?.user?.status === 'Completed' && (
                                <span
                                  className='badge badge-primary px-5 py-2 mt-1'
                                  style={{ backgroundColor: '#3F4254', color: '#FFF' }}
                                >
                                  Completed
                                </span>
                              )}
                              {applicant?.user?.status === 'Dropped' && (
                                <span
                                  className='badge badge-primary px-5 py-2 mt-1'
                                  style={{ backgroundColor: '#FFF4DE', color: '#FFA800' }}
                                >
                                  Dropped
                                </span>
                              )}
                            </td>
                            <td className=''>
                              <div className='d-flex flex-row gap-3 mt-1'>
                                <div
                                  className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_1'
                                  style={{ width: 20, height: 30 }}
                                  onClick={() => {
                                    setTrainerId(applicant.user.id)
                                  }}
                                >
                                  <img
                                    className=''
                                    src={toAbsoluteUrl('/media/svg/person.svg')}
                                  ></img>
                                </div>
                                <div
                                  className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                  onClick={() => {
                                    setActiveApplicant(applicant)
                                  }}
                                  style={{ width: 20, height: 30 }}
                                >
                                  <KTIcon
                                    iconName='arrow-right'
                                    className='ki-duotone ki-arrow-right text-primary fs-1'
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='col-xl-6'>
        <div className='card card-flush h-xl-100 cursor-pointer'>
          <div className='card-header'>
            <div className='card-title flex-column'>
              <h3 className='mt-4'>Tasks</h3>
              <p className='fs-9 text-secondary fw-light'>
                {activeApplicant?.training.length} tasks listed
              </p>
            </div>
          </div>
          <div className='card-body py-3'>
            {isApplicantsLoading ? (
              <div className='v-100 position-relative' style={{ minHeight: '500px' }}>
                <div
                  className='page-loading position-absolute'
                  style={{
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <span
                    className='spinner-border text-primary'
                    data-kt-app-page-loading='on'
                    role='status'
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </span>
                </div>
              </div>
            ) : (
              <div className=''>
                <table className='table table-striped table-row-gray-300'>
                  <thead>
                    <tr className='fw-bolder fs-6 text-gray-800'>
                      <th className='fw-bolder text-secondary ' style={{ paddingLeft: '7px' }}>
                        Name
                      </th>
                      <th className='fw-bolder text-secondary' style={{ width: '40%' }}>
                        TOTAL ACTIVE TRAINERS
                      </th>
                      <th className='fw-bolder text-secondary'>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeApplicant?.training?.length > 0 &&
                      activeApplicant?.training.map((program) => {
                        return (
                          <tr key={program.id}>
                            <td style={{ paddingLeft: '7px' }}>
                              <p className='fw-bolder m-0'>{program.name}</p>
                            </td>
                            <td className=''>
                              <p className=''>{program.active_trainers}</p>
                            </td>
                            <td>
                              <div
                                className='d-flex flex-row h-100 gap-3 mt-1'
                                style={{ paddingRight: 10 }}
                              >
                                {program.approved ? (
                                  <div
                                    className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                    onClick={() => {
                                      programAction(false, program.id)
                                    }}
                                    style={{ width: 20, height: 30 }}
                                  >
                                    {loadingButtons.includes(program.id) ? (
                                      <div className='page-loading'>
                                        <span
                                          className='spinner-border text-primary'
                                          data-kt-app-page-loading='on'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>Loading...</span>
                                        </span>
                                      </div>
                                    ) : (
                                      <img
                                        className=''
                                        src={toAbsoluteUrl('/media/svg/ShapeX.svg')}
                                      ></img>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                    onClick={() => {
                                      programAction(true, program.id)
                                    }}
                                    style={{ width: 20, height: 30 }}
                                  >
                                    {loadingButtons.includes(program.id) ? (
                                      <div className='page-loading'>
                                        <span
                                          className='spinner-border text-primary'
                                          data-kt-app-page-loading='on'
                                          role='status'
                                        >
                                          <span className='visually-hidden'>Loading...</span>
                                        </span>
                                      </div>
                                    ) : (
                                      <img
                                        className=''
                                        src={toAbsoluteUrl('/media/svg/Shape.svg')}
                                      ></img>
                                    )}
                                  </div>
                                )}
                                <div
                                  className='btn btn-sm btn-light d-flex justify-content-center align-items-center'
                                  style={{ width: 20, height: 30 }}
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_training_description'
                                  onClick={() => {
                                    // setIndependentTrainingId(program?.id)
                                    setSelectedTraining(program)
                                  }}
                                >
                                  <img
                                    className=''
                                    src={toAbsoluteUrl('/media/svg/File.svg')}
                                  ></img>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      <ProfileModal id={trainerId} role={'trainer'} />
      <TrainingDescriptionModal trainingId={independentTrainingId}
        trainingData={selectedTraining}
      />
    </div>
  )
}

export default TrainingApplicantsTable
