import { KTSVG, toAbsoluteUrl } from '_metronic/helpers'
import { API_URL } from 'app/consts/consts'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
interface IProgram {
  id: number
  name: string
  eqf_level: number | null
  ects: number | null
  total_training: number
  active_training: number
  authorization_required: boolean
}
export const TrainingAuthorizationTable = () => {
  const [programs, setPrograms] = useState<IProgram[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getPrograms = async () => {
    setIsLoading(true)
    const response = await axios.get(`${API_URL}/trainings/programs`)
    setPrograms(response.data.data)
    setIsLoading(false)
  }
  useEffect(() => {
    getPrograms()
  }, [])
  return (
    <div className='card card-flush h-xl-100 cursor-pointer mt-10'>
      <div className='card-header'>
        <div className='card-title flex-column'>
          <h3 className='mt-4'>Programs</h3>
          <p className='fs-9 text-secondary fw-light'>{programs.length} programs listed</p>
        </div>
      </div>
      <div className='card-body py-3'>
        {isLoading ? (
          <div className='v-100 position-relative' style={{ minHeight: '400px' }}>
            <div
              className='page-loading position-absolute'
              style={{
                top: '50%',
                left: '50%',
              }}
            >
              <span
                className='spinner-border text-primary'
                data-kt-app-page-loading='on'
                role='status'
              >
                <span className='visually-hidden'>Loading...</span>
              </span>
            </div>
          </div>
        ) : (
          <div className=''>
            <table className='table table-row-bordered table-row-gray-300 gy-7'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800'>
                  <th className='fw-bolder text-secondary'>Name</th>
                  <th className='fw-bolder text-secondary'>STATUS</th>
                  <th className='fw-bolder text-secondary text-center'>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {programs.map((program) => (
                  <tr>
                    <td>{program.name}</td>
                    <td>
                      {program.authorization_required ? (
                        <span className='badge badge-danger px-5 py-2'>Authorization Required</span>
                      ) : null}
                    </td>
                    <td className='d-flex justify-content-center'>
                      <Link
                        to={`${program.id}`}
                        className='btn btn-sm btn-light d-flex justify-content-center align-items-center w-40px h-30px'
                      >
                        <img
                          className='w-20px h-20px'
                          src={toAbsoluteUrl('/media/svg/Right-2.svg')}
                        ></img>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>)}
      </div>
    </div>
  )
}
