import React from 'react'
import {Route, Routes} from 'react-router-dom'
import MatchingProgramTable from './pages/MatchingProgramTable.page'
import MatchProgram from './pages/MatchProgram.page'


export const MatchingWrapper = () => {
  return (
    <Routes>
      <Route path='/*' element={<MatchingProgramTable />}></Route>
      <Route path='/:matchingId' element={<MatchProgram/>}></Route>
    </Routes>
  )
}
