import React, {useEffect, useState} from 'react'
import UserList from '../components/UserList.component'
import ChatCard from '../components/ChatCard.component'
import axios from 'axios'
import {API_URL} from 'app/consts/consts'
import {useParams} from 'react-router-dom'

const Chat = () => {
  const {userId} = useParams()
  const [selectedChat, setSelectedChat] = useState<any>(null)
  const [intervalId, setIntervalId] = useState<any>(null)
  const updateIntervalId = (id: any) => {
    setIntervalId(id)
  }
  const handleChatSelect = (chat: any) => {
    console.log(chat)
    setSelectedChat(chat)
  }
  const getSelectedChat = async () => {
    try {
      const response = await axios.get(`${API_URL}/users/${userId}`)
      setSelectedChat({participant: {id: response.data?.data?.id, name: response.data?.data?.username, role: response.data?.data?.role}})
      console.log(response)
    } catch (err) {
      console.log('err')
      setSelectedChat(null)
    }
  }
  useEffect(() => {
    getSelectedChat()
  }, [])
  return (
    <div className='row'>
      <UserList onSelectChat={handleChatSelect} />
      <ChatCard chat={selectedChat} setIntervalId={updateIntervalId} />
    </div>
  )
}

export default Chat
