import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Chat from './page/Chat.page'

const ChatWrapper = () => {
    return (
        <Routes>
            <Route
                path='/:userId'
                element={<Chat />}>
            </Route>
            <Route
                path='/*'
                element={<Chat />}>
            </Route>
        </Routes>
    )
}

export default ChatWrapper