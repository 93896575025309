import {toAbsoluteUrl} from '_metronic/helpers'
import clsx from 'clsx'

type Props = {
  className?: string
  bgImage?: string
  title?: string
  countNumber: number;
  wholeAmount: number;
  completedName: string
  color: string
}
const DashboardBackgroundTiles = ({
  className,
  countNumber,
  wholeAmount,
  completedName,
  //   bgImage = toAbsoluteUrl('/media/patterns/vector-1.png'),
  color,
  title = 'Properties',
}: Props) => {
  const percentCompleted = (countNumber / wholeAmount) * 100;
  return (
    <div
      className='card card-flush bgi-no-repeat bgi-size-cover bgi-position-x-end mb-5'
      style={{
        backgroundColor: color,
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{countNumber}/{wholeAmount}</span>

          <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{completedName}</span>
        </div>
      </div>

      <div className='card-body d-flex align-items-end pt-0'>
        <div className='d-flex align-items-center flex-column mt-3 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
            <span>Percentage</span>
            <span>{percentCompleted.toFixed(0)}%</span>
          </div>

          <div className='progress h-8px mx-3 w-100 bg-white'>
            <div
              className='progress-bar h-8px'
              role='progressbar'
              style={{width: `${percentCompleted}%`, backgroundColor: '#3699FF'}}
            ></div>
          </div>
          <div className='progress'>
            <div
              className='progress-bar bg-primary'
              role='progressbar'
              style={{width: '20%'}}
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DashboardBackgroundTiles}
